import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { Button, Input } from 'components/ui';
import { jobsService } from 'services';
import './Reschedule.scss';

const formatDate = 'MM/dd/yyyy HH:mm:ss';

interface RescheduleProps {
  timeOffset?: number;
  onClose: () => void;
}

const Reschedule: FC<RescheduleProps> = (props) => {
  const { timeOffset = 0, onClose } = props;
  const interval = useRef<NodeJS.Timer | null>(null);
  const { sjId } = useParams<{ sjId: string }>();
  const [currentUserTime, setCurrentUserTime] = useState(setCustomerTime);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationFn: async (d: string) => {
      try {
        await jobsService.rescheduleJob({ sjId: sjId ?? '', d });
        enqueueSnackbar('Job has been rescheduled', { variant: 'success' });

        onClose();
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      date: '',
      time: ''
    }
  });

  function setCustomerTime() {
    const myOffset = new Date().getTimezoneOffset() / 60;
    const totalOffset = timeOffset + myOffset;

    if (timeOffset < 0) {
      return DateTime.now()
        .minus({ hour: Math.abs(totalOffset) })
        .toFormat(formatDate);
    }

    return DateTime.now().plus({ hour: totalOffset }).toFormat(formatDate);
  }

  const submit = handleSubmit((values) => {
    const { date, time } = values;
    const newDate = new Date(date);
    const dateMDY = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getFullYear()}`;
    mutate(`${dateMDY} ${time}`);
  });

  useEffect(() => {
    interval.current = setInterval(() => {
      const currentTime = setCustomerTime();
      setCurrentUserTime(currentTime);
    }, 1000);
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  return (
    <div className="Reschedule">
      <h4 className="mb-2 fw-500 font-lg">Reschedule</h4>
      <p className="mb-1 fw-500">Customer current time:</p>
      <p className="mb-2 fw-500">{currentUserTime}</p>
      <Input
        fullWidth
        type="date"
        label="Job Date"
        className="mb-2"
        error={errors.date?.message}
        {...register('date', { required: 'Date field is required' })}
      />
      <Input
        fullWidth
        type="time"
        label="Job Time"
        className="mb-2"
        error={errors.time?.message}
        {...register('time', { required: 'Time field is required' })}
      />
      <Button fullWidth onClick={submit}>
        Confirm
      </Button>
    </div>
  );
};

export default Reschedule;
