import { createContext, useContext } from 'react';

export const AccordionContext = createContext<{
  expanded: boolean;
  toogleExpanded: () => void;
} | null>(null);

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (context === null) {
    throw new Error('useAccordionContext must be used within an AccordionContext.Provider');
  }

  return context;
};
