import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import CloseJob from 'components/CloseJob';
import { techService } from 'services/techService';
import { CloseJobData } from 'types/jobs';

const CloseJobTechPage = () => {
  const navigate = useNavigate();
  const { sjId, techToken } = useParams<{ sjId: string; techToken: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const { data: jobData, isLoading } = useQuery({
    queryKey: ['job', sjId],
    queryFn: () => techService.getJob(sjId || '', techToken || ''),
    keepPreviousData: true
  });

  const { data: techData, isLoading: isTechLoading } = useQuery({
    queryKey: ['techInfo'],
    queryFn: () => techService.getTech(techToken || ''),
    keepPreviousData: true
  });

  const { mutate, isLoading: isCloseLoading } = useMutation({
    mutationFn: async (values: CloseJobData) => {
      try {
        const { partsPhoto, ...rest } = values;
        const resp = await techService.closelJob({
          ...rest,
          jobId: sjId || '',
          token: techToken || ''
        });
        if (resp.data.success === 'true') {
          enqueueSnackbar('Job has been closed', { variant: 'success' });
          navigate(`/opensms_tech/${sjId}/${techToken}`);
        } else {
          enqueueSnackbar(resp.data.msg, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  return (
    <CloseJob
      submitFn={mutate}
      isCloseLoading={isCloseLoading}
      job={{
        totalCost: jobData?.data.job.TotalCost,
        partsCost: jobData?.data.job.PartsCost
      }}
      id={jobData?.data.job.JobId}
      isJobLoading={isLoading || isTechLoading}
      cashOnly={techData?.data.Tech.CashOnly === 1}
    />
  );
};

export default CloseJobTechPage;
