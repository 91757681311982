import { useRef } from 'react';

import { usePrevValue } from './usePrevValue';

export const useCustomCompare = <T>(value: T, equalFunc: (a: T, b: T) => boolean) => {
  const changeRef = useRef(0);
  const prevValue = usePrevValue(value);

  if (changeRef.current === 0 || !equalFunc(prevValue as T, value)) {
    changeRef.current++;
  }

  console.log(changeRef.current);
  return changeRef.current;
};
