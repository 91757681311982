import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ArrowLeftIcon } from 'assets';
import { Button, Divider, FileInput, Input, PageLoader, Switcher, Textarea } from 'components/ui';
import './CloseJob.scss';
import { CloseJobData } from 'types/jobs';
import { PaymentMethods, PORStatuses } from 'types/generalData';

interface Job {
  totalCost?: number | null;
  partsCost?: number | null;
  paymentOverrideStatus?: number | null;
  CustomerPayMethod?: number | null;
  photoRequiredOverPartPriceOf?: number | null;
  jobId?: number;
}

interface CloseJobProps {
  submitFn: (args: CloseJobData) => void;
  isCloseLoading: boolean;
  job?: Job;
  id?: number;
  isJobLoading: boolean;
  cashOnly?: boolean;
}

const CloseJob: FC<CloseJobProps> = (props) => {
  const { isCloseLoading, submitFn, job, id, isJobLoading, cashOnly = false } = props;

  const navigate = useNavigate();

  const defaultPaymentMethodValue = (defaultValue: number) => {
    return defaultValue === PaymentMethods.Cash ? true : false;
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors }
  } = useForm({
    defaultValues: {
      totalCost: '',
      partsCost: '',
      comments: '',
      invoice: '',
      paymentMethod: true,
      partsImg: ''
    }
  });

  useEffect(() => {
    if (cashOnly) {
      setValue('paymentMethod', true);
    } else {
      const defaulPaymentMethodValue = defaultPaymentMethodValue(
        job?.CustomerPayMethod || PaymentMethods.Cash
      );
      setValue('paymentMethod', defaulPaymentMethodValue);
    }

    if (job?.paymentOverrideStatus === PORStatuses.Approwed) {
      setValue('totalCost', String(job?.totalCost || ''));
      setValue('partsCost', String(job?.partsCost || ''));
    }
  }, [job, cashOnly]);

  const submit = handleSubmit((values) => {
    const { partsImg, paymentMethod, totalCost, partsCost, comments, invoice } = values;
    const paymentMethodTransformed =
      paymentMethod === true ? PaymentMethods.Cash : PaymentMethods.CreditCard;
    const imgFile = (partsImg as unknown as FileList)?.[0];
    const payload = {
      totalCost: totalCost,
      partsCost: partsCost || '0',
      comments: comments,
      invoiceNumber: invoice,
      customerPayMethod: paymentMethodTransformed,
      partsPhoto: imgFile
    };

    submitFn(payload);
  });

  const isNeededPartsPhoto =
    job?.photoRequiredOverPartPriceOf && +watch('partsCost') >= job?.photoRequiredOverPartPriceOf;
  if (isJobLoading) {
    return <PageLoader />;
  }
  return (
    <div className="CloseJob">
      <div className="d-flex gap-1 align-center p-2 ">
        <ArrowLeftIcon className="pointer" onClick={() => navigate(-1)} />
        <p className="font-lg">Back</p>
      </div>
      <Divider />
      <div className="CloseJob-content">
        <p className="fw-500 mb-1 font-lg">Close Job # {id}</p>
        <Input
          label="Total price"
          endAdornment="$"
          fullWidth
          className="mb-2"
          type="number"
          error={errors.totalCost?.message}
          disabled={Boolean(job?.totalCost) && job?.paymentOverrideStatus === 1}
          {...register('totalCost', { required: 'Total cost is required field' })}
        />
        <Input
          label="Parts cost"
          endAdornment="$"
          fullWidth
          type="number"
          disabled={Boolean(job?.partsCost) && job?.paymentOverrideStatus === 1}
          {...register('partsCost')}
          className="mb-2"
        />
        <Textarea label="Comments" rows={3} fullWidth className="mb-2" {...register('comments')} />
        <div className="mb-2">
          <p className="mb-1 fw-700">Payment method</p>
          <Switcher
            disabled={cashOnly}
            stateOff="Credit Card"
            stateOn="Cash"
            {...register('paymentMethod')}
          />
        </div>
        <Input
          label="Invoice"
          endAdornment="#"
          fullWidth
          className="mb-2"
          type="number"
          {...register('invoice')}
        />
        {isNeededPartsPhoto && (
          <FileInput
            label="Parts photo"
            placeholder="Upload Image"
            className="mb-2"
            errorText={errors.partsImg?.message}
            {...register('partsImg', { required: true })}
          />
        )}
      </div>
      <div className="CloseJob-footer">
        <Button
          fullWidth
          onClick={submit}
          disabled={!isValid || isCloseLoading}
          loading={isCloseLoading}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default CloseJob;
