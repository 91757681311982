import classNames from 'classnames';
import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';

import { useCombinedRef } from 'hooks/useCombinedRef';
import './Textarea.scss';

export type TextAreaProp = {
  label?: string;
  id?: string;
  error?: string | undefined;
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  maxHeight?: string;
  autoResize?: boolean;
} & JSX.IntrinsicElements['textarea'];

const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProp>((props, ref) => {
  const {
    id,
    label,
    fullWidth = false,
    error,
    className,
    endAdornment,
    onChange,
    maxHeight,
    value,
    style,
    autoResize = false,
    ...restProps
  } = props;

  const textareaClass = classNames({
    'textarea-container': true,
    'textarea-error': !!error,
    'textarea-fullWidth': fullWidth
  });

  const initialHeight = 42;
  const internalRef = useRef<HTMLTextAreaElement | null>(null);

  const cbRef = useCombinedRef(ref, internalRef);

  useLayoutEffect(() => {
    if (!autoResize) return;
    if (internalRef.current) {
      internalRef.current.style.height = internalRef.current.scrollHeight + 'px';
    }

    if (internalRef.current && !internalRef.current.value) {
      internalRef.current.style.height = initialHeight + 'px';
    }
  }, [value]);

  const internalOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e);
    if (!autoResize) return;

    if (!e.target.value.length) {
      e.target.style.height = initialHeight + 'px';
      return;
    }

    e.target.style.height = e.target.scrollHeight + 'px';
  };

  return (
    <div className={`${textareaClass} ${className}`}>
      <label className="textarea-label" htmlFor={id}>
        {label}
      </label>
      <div className="textarea-wrapper">
        <textarea
          id={id}
          style={{ ...style, maxHeight }}
          {...restProps}
          ref={cbRef}
          className="textarea-field"
          onChange={internalOnChange}
          value={value}
        />
        <div className="textarea-endAdornment">{endAdornment}</div>
      </div>
      <p className="textarea-error-message">{error}</p>
    </div>
  );
});

Textarea.displayName = 'textarea';

export default Textarea;
