import { useInsertionEffect, useRef } from 'react';

export const useLatest = <Value>(value: Value) => {
  const ref = useRef(value);

  useInsertionEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};
