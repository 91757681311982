import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';

import { generalService } from 'services';
import { GeneralData } from 'types/generalData';

interface IGeneralDataContext {
  generalData?: GeneralData;
  isGeneralDataLoading: boolean;
}

const GeneralDataContext = createContext<IGeneralDataContext | null>(null);

export const GeneralDataProvider = (props: { children: React.ReactNode }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['generalData'],
    queryFn: generalService.getGeneralData
  });

  const value = useMemo(
    () => ({
      generalData: data?.data.GeneralData,
      isGeneralDataLoading: isLoading
    }),
    [data, isLoading]
  );
  return <GeneralDataContext.Provider value={value} {...props} />;
};

export const useGeneralData = () => {
  const context = useContext(GeneralDataContext);
  if (context === null) {
    throw Error('useGeneralData have to be used inside AuthProvider');
  }
  return context;
};
