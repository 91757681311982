import { useEffect } from 'react';

import { useLatest } from './useLatest';

type GetWindowEvent<Type> = Type extends keyof WindowEventMap ? WindowEventMap[Type] : Event;

function useWindowEvent<Type extends string>(
  type: Type,
  cb: (event: GetWindowEvent<Type>) => void
): void;
function useWindowEvent<Type extends string>(type: Type, cb: (event: Event) => void) {
  const callBack = useLatest(cb);

  useEffect(() => {
    const handler = (event: Event) => {
      callBack.current(event);
    };

    window.addEventListener(type, handler);

    return () => {
      window.removeEventListener(type, handler);
    };
  }, [type, callBack]);
}

export { useWindowEvent };
