import { AxiosInstance } from 'axios';

import { axiosInst } from 'axiosInst';

interface UpdateUserArgs {
  TechContactId: string | number;
  Email: string;
  Phone: string;
  Name: string;
  Comments: string;
  ReminderCalls: number;
}

class UserService {
  constructor(private axiosInst: AxiosInstance) {
    this.axiosInst = axiosInst;
  }

  _preparePayload = (items: Record<any, any>) => {
    const formData = new FormData();
    const data = {
      items: [items]
    };
    formData.append('data', JSON.stringify(data));
    return new URLSearchParams(formData as any);
  };

  async updateUserProfile(args: UpdateUserArgs) {
    const payload = this._preparePayload(args);
    return axiosInst.post('api/TechContact/SelfEdit/', payload);
  }

  async deactivateAccount() {
    return axiosInst.post(`/api/account/terminate`);
  }
}

export const userService = new UserService(axiosInst);
