import React, { FC } from 'react';
import './MoreActions.scss';
import { useNavigate, useParams } from 'react-router-dom';

import { CalendarPlusIcon, ClockCountdownIcon, MoneyIcon, ShareIcon, StampIcon } from 'assets';
import { DrawerContent } from 'pages/JobDetailsPage/JobDetailsPage';

interface IMoreActionsProps {
  setContent: (contentType: DrawerContent) => void;
  paymentOverrideStatus?: number | null;
}

const MoreActions: FC<IMoreActionsProps> = (props) => {
  const { paymentOverrideStatus = null } = props;
  console.log(paymentOverrideStatus);

  const navigate = useNavigate();
  const { sjId } = useParams<{ sjId: string }>();
  const { setContent } = props;
  return (
    <div className="MoreActions">
      <h4 className="fw-700 mb-2 primary-color">More Actions</h4>
      <ul className="main-list">
        <li className="main-list-item" onClick={() => setContent('reschedule')}>
          <CalendarPlusIcon />
          <p>Reschedule</p>
        </li>
        {paymentOverrideStatus === null && (
          <li
            className="main-list-item"
            role="link"
            onClick={() => navigate(`/payment-request/${sjId}`)}>
            <MoneyIcon />
            <p>Override Payment</p>
          </li>
        )}
        <li className="main-list-item" role="button" onClick={() => setContent('est-job-arrival')}>
          <ClockCountdownIcon />
          <p>EST Arrival</p>
        </li>
        <li
          className="main-list-item"
          role="button"
          onClick={() => setContent('est-job-complition')}>
          <StampIcon />
          <p>EST Job Completion</p>
        </li>
      </ul>
    </div>
  );
};

export default MoreActions;
