import { FC, createContext, useContext, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { authService } from 'services';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import { clearTimerFormLocalstorage } from 'helpers/enterCodePage';
import { emitCustomStorageEvent } from 'utils/emitCustomStorageEvent';

const { st_token } = LocalStorageKeys;

interface Authontext {
  login: (subTechId: string, code: string) => void;
  logout: () => void;
  isAuth: boolean;
  isLoginLoading: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const AuthContext = createContext<Authontext | null>(null);

const getToken = () => {
  return localStorage.getItem(st_token) ? true : false;
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const [isAuth, setIsAuth] = useState(getToken);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: verifyCode, isLoading: isLoginLoading } = useMutation({
    mutationKey: ['verifyCodeLogin'],
    mutationFn: async ({ subTechId, code }: { subTechId: string; code: string }) => {
      try {
        const { data } = await authService.loginVerifyCode(subTechId, code);
        if (data.success === 'true') {
          localStorage.setItem(st_token, data.Token);
          setIsAuth(true);
          emitCustomStorageEvent();
          clearTimerFormLocalstorage();
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  const login = (subTechId: string, code: string) => {
    verifyCode({ subTechId, code });
  };

  const logout = () => {
    localStorage.removeItem(st_token);
    setIsAuth(false);
  };

  const value = useMemo(
    () => ({
      login,
      logout,
      isAuth,
      isLoginLoading
    }),
    [isAuth, isLoginLoading]
  );
  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw Error('useAuth have to be used inside AuthProvider');
  }
  return context;
};
