import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import RequestSentModal from './components/RequestSentModal';

import { ArrowLeftIcon, CopyIcon } from 'assets';
import { Button, Input, PageLoader, Switcher, Textarea } from 'components/ui';
import Modal from 'components/ui/Modal';
import { useLocationService } from 'contexts/LocationContext';
import { jobsService } from 'services';
import { PaymentOverrideArgs } from 'services/jobsService';
import './PaymentRequestPage.scss';
import { PaymentMethods } from 'types/generalData';

const PaymentRequestPage = () => {
  const navigate = useNavigate();
  const params = useParams<{ sjId: string }>();
  const { currentLocation } = useLocationService();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading: isJobLoading } = useQuery({
    queryKey: ['job', params.sjId],
    queryFn: () => jobsService.getJob(params.sjId || ''),
    keepPreviousData: true
  });

  const { mutate, isLoading: isPORLoading } = useMutation({
    mutationFn: async (values: PaymentOverrideArgs) => {
      try {
        const res = await jobsService.paymentOverrideRequestJob(values);
        if (res.data.success === 'true') {
          setIsOpen(true);
        } else {
          console.log(res.data);
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  const [isOpen, setIsOpen] = useState(false);
  const defaultPaymentMethodValue = (defaultValue: number) => {
    return defaultValue === PaymentMethods.Cash ? true : false;
  };

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      totalCost: '',
      partsCost: '',
      mySharePerc: '',
      myShareCash: '',
      techSharePerc: '',
      techShareCash: '',
      paymentMethod: false,
      techComments: ''
    },
    mode: 'all'
  });

  const submit = handleSubmit((values) => {
    const { paymentMethod } = values;
    const paymentMethodTransformed =
      paymentMethod === true ? PaymentMethods.Cash : PaymentMethods.CreditCard;
    const payload = {
      sjId: params.sjId || '',
      jobId: data?.data.jobId || '',
      LL: `${currentLocation.lat},${currentLocation.lng}`,
      totalCost: values.totalCost,
      techShare: values.myShareCash,
      companyShare: values.techShareCash,
      customerPaymentType: paymentMethodTransformed,
      partsCost: values.partsCost,
      techComments: values.techComments
    };
    mutate(payload);
  });

  useEffect(() => {
    const defaulPaymentMethodValue = defaultPaymentMethodValue(
      data?.data.CustomerPayMethod || PaymentMethods.Cash
    );
    setValue('paymentMethod', defaulPaymentMethodValue);
  }, [data]);

  const totalCostChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const mySharePerc = +watch('mySharePerc');
    const techSharePerc = +watch('techSharePerc');
    const myShareCash = (value * mySharePerc) / 100;
    const techShareCash = (value * techSharePerc) / 100;
    setValue('myShareCash', String(myShareCash));
    setValue('techShareCash', String(techShareCash));
  };
  const techSharePercChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const totalCost = +watch('totalCost');
    const techShareCash = (totalCost * value) / 100;
    const myShareCash = totalCost - techShareCash;
    const mySharePerc = 100 - value;
    setValue('techShareCash', String(techShareCash));
    setValue('myShareCash', String(myShareCash));
    setValue('mySharePerc', String(mySharePerc.toFixed(2)));
  };
  const techShareCasheChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const totalCost = +watch('totalCost');
    const techSharePerc = (value / totalCost) * 100;
    const myShareCache = totalCost - value;
    const mySharePerc = 100 - techSharePerc;
    setValue('myShareCash', String(myShareCache));
    setValue('mySharePerc', String(mySharePerc.toFixed(2)));
    setValue('techSharePerc', String(techSharePerc.toFixed(2)));
  };
  const mySharePercChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const totalCost = +watch('totalCost');
    const myShareCache = (totalCost * value) / 100;
    const techShareCache = totalCost - myShareCache;
    const techSharePerc = 100 - value;
    setValue('myShareCash', String(myShareCache));
    setValue('techShareCash', String(techShareCache));
    setValue('techSharePerc', String(techSharePerc.toFixed(2)));
  };
  const myShareCasheChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const totalCost = +watch('totalCost');
    const mySharePerc = (value / totalCost) * 100;
    const techShareCache = totalCost - value;
    const techSharePerc = 100 - mySharePerc;
    setValue('mySharePerc', String(mySharePerc.toFixed(2)));
    setValue('techShareCash', String(techShareCache));
    setValue('techSharePerc', String(techSharePerc.toFixed(2)));
  };

  const closePopup = () => {
    setIsOpen(false);
    navigate(`job-details/${data?.data.sjId}`);
  };

  if (isJobLoading) {
    return <PageLoader />;
  }
  return (
    <div className="PaymentRequestPage">
      <div className="d-flex gap-1 align-center p-2 mb-2 pointer">
        <ArrowLeftIcon onClick={() => navigate(-1)} />
        <p className="fw-700 font-lg"> Payment Request</p>
      </div>
      <div className="PaymentRequestPage-content">
        <div className="card mb-2">
          <div className="job-id mb-1">
            #{data?.data.jobId} {data?.data.ExternalJobId && `(${data?.data.ExternalJobId})`}
            <CopyIcon />
          </div>
          <p className="font-lg fw-500">Ignition Key</p>
          <p className="text-secondary">Volkswagen Touareg 2004-2006</p>
        </div>
        <div className="mb-2">
          <p className="fw-700 mb-1">Payment method</p>
          <Switcher stateOff="Credit card" stateOn="Cash" {...register('paymentMethod')} />
        </div>
        <Input
          label="Total cost"
          fullWidth
          className="mb-2"
          endAdornment="$"
          type="number"
          error={errors.totalCost?.message}
          {...register('totalCost', {
            required: 'Total cost field is required',
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              totalCostChanged(e);
            }
          })}
        />
        <Input
          label="Parts cost"
          type="number"
          fullWidth
          className="mb-2"
          endAdornment="$"
          {...register('partsCost')}
        />
        <p className="fw-700">My share</p>
        <p className="text-secondary font-sm fw-500"> including parts</p>
        <div className="d-flex gap-1 mb-2">
          <Input
            endAdornment="$"
            type="number"
            {...register('myShareCash', {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                myShareCasheChanged(e);
              }
            })}
          />
          <Input
            endAdornment="%"
            type="number"
            error={errors.mySharePerc?.message}
            {...register('mySharePerc', {
              required: 'This field is required',
              validate: {
                max: (v) => +v <= 100 || 'Must be less than 100',
                min: (v) => +v >= 0 || 'Must be more than 0'
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                mySharePercChanged(e);
              }
            })}
          />
        </div>
        <p className="fw-700">Master Tech Share</p>
        <div className="d-flex gap-1 mb-2">
          <Input
            endAdornment="$"
            type="number"
            {...register('techShareCash', {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                techShareCasheChanged(e);
              }
            })}
          />
          <Input
            endAdornment="%"
            error={errors.techSharePerc?.message}
            type="number"
            {...register('techSharePerc', {
              validate: {
                max: (v) => +v <= 100 || 'Must be less than 100',
                min: (v) => +v >= 0 || 'Must be more than 0'
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                techSharePercChanged(e);
              }
            })}
          />
        </div>
        <Textarea
          label="Comments"
          className="mb-2"
          error={errors.techComments?.message}
          {...register('techComments', { required: 'This field is required' })}
        />
      </div>
      <div className="PaymentRequestPage-footer">
        <Button
          fullWidth
          className="mb-1"
          onClick={submit}
          disabled={isPORLoading}
          loading={isPORLoading}>
          Confirm
        </Button>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <RequestSentModal onClose={closePopup} />
      </Modal>
    </div>
  );
};

export default PaymentRequestPage;
