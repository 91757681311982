import { IMessage } from 'types/chat';

export const groupMessagesByDate = (msgs: IMessage[]) => {
  const ht = {} as Record<string, IMessage[]>;
  for (const msg of msgs) {
    const msgDate = new Date(msg.SendDate).toDateString();
    if (ht[msgDate]) ht[msgDate].push(msg);
    else ht[msgDate] = [msg];
  }
  return ht;
};
