import axios from 'axios';

import { config } from 'config';

interface TechGeneralData {
  JobStatuses: {
    Id: number;
    Status: string;
  }[];
  JobTypes: {
    JobTypeId: number;
    Name: string;
  }[];
}

interface Tech {
  CashOnly: number | null;
}

interface TechResponse {
  success: boolean;
  Tech: Tech;
}

interface TechJob {
  JobId: number;
  Name: string;
  Status: number;
  CreatedDate: string;
  Price: string;
  Type: number;
  Address: string;
  TotalCost: number | null;
  PartsCost: number | null;
  ExternalJobId?: number | null;
}

interface TechJobResponse {
  success: boolean;
  job: TechJob;
}

class TechService {
  constructor() {}

  _preparePayload = (items: Record<any, any>) => {
    const formData = new FormData();
    const data = {
      items: [items]
    };
    formData.append('data', JSON.stringify(data));
    return new URLSearchParams(formData as any);
  };

  getGeneralData = (token: string) => {
    return axios.post<TechGeneralData>(config.apiLink + '/api/Techadmin/GetGeneralData', null, {
      headers: { token }
    });
  };

  getJob = (sjId: string, token: string) => {
    return axios.post<TechJobResponse>(config.apiLink + `/api/Techadmin/GetJob/${sjId}`, null, {
      headers: { token }
    });
  };

  getTech = (token: string) => {
    return axios.post<TechResponse>(config.apiLink + '/api/Tech/Get/', null, {
      headers: { token }
    });
  };

  cancelJob = async (cancelJobData: {
    token: string;
    jobId: string;
    comments: string;
    reasonId: string;
  }) => {
    const { token, ...rest } = cancelJobData;
    const payload = this._preparePayload(rest);
    return axios.post(config.apiLink + `/api/Job/Cancel/`, payload, {
      headers: { token }
    });
  };

  closelJob = async (args: {
    jobId: string;
    totalCost: string;
    partsCost: string;
    comments: string;
    invoiceNumber: string;
    customerPayMethod: number;
    token: string;
  }) => {
    const { token, ...rest } = args;
    const payload = this._preparePayload(rest);
    return axios.post(config.apiLink + `/api/Job/Close`, payload, { headers: { token } });
  };
}

export const techService = new TechService();
