import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CancelJob from 'components/CancelJob';
import { techService } from 'services/techService';

const reasons = [
  { Id: 1, Reason: 'Company quote is too high' },
  { Id: 2, Reason: 'Customer is no longer at the location' },
  { Id: 3, Reason: 'Job duplication error' },
  { Id: 4, Reason: 'No technician available' },
  { Id: 5, Reason: 'We do not service this area' },
  { Id: 6, Reason: 'Customer is failing to follow up on our calls' },
  { Id: 7, Reason: 'Inaccurate information entered' },
  { Id: 8, Reason: 'Customer got someone else' },
  { Id: 9, Reason: 'Poor quality transaction' },
  { Id: 10, Reason: 'Service type is not within our criteria' },
  { Id: 11, Reason: 'Customer has found another solution' },
  { Id: 12, Reason: 'Technician is unable to complete this ignition service' },
  { Id: 13, Reason: 'Technician is not complying' },
  { Id: 14, Reason: 'Technician is unavailable' },
  { Id: 15, Reason: 'Miscellaneous' },
  { Id: 16, Reason: 'Follow up' }
];

const CancelJobTechPage = () => {
  const params = useParams<{ sjId: string; techToken: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isJobLoading } = useQuery({
    queryKey: ['job', params.sjId],
    queryFn: () => techService.getJob(params.sjId || '', params.techToken || ''),
    keepPreviousData: true
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['cancel', params.sjId],
    mutationFn: async (values: { reason: string; comments: string }) => {
      const { comments, reason } = values;

      try {
        const resp = await techService.cancelJob({
          comments,
          reasonId: reason,
          jobId: params.sjId || '',
          token: params.techToken || ''
        });
        if (resp.data.success === 'true') {
          enqueueSnackbar('Job has been canceled', { variant: 'success' });
          navigate(`/opensms_tech/${params.sjId}/${params.techToken}`, { replace: true });
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  return (
    <CancelJob
      submitFn={mutate}
      isCancelLoading={isLoading}
      isJobLoading={isJobLoading}
      cancelReasons={reasons}
    />
  );
};

export default CancelJobTechPage;
