import { useQuery } from '@tanstack/react-query';
import React, { FC, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { BriefcaseIcon, CarIcon, CaretRightIcon, HouseLineIcon, WrenchIcon } from 'assets';
import { authService } from 'services';
import jobtypes from 'assets/imgs/Jobtypes.png';
import { Accordion, AccordionBody, AccordionSummary } from 'components/ui/Accordion';
import Checkbox from 'components/ui/Checkbox';
import { Button, Divider } from 'components/ui';
import './JobTypesForm.scss';

interface JobType {
  JobTypeId: number;
  Name: string;
  ParentId: number | null;
}

const jobTypesIconMap: Record<number, React.ReactNode> = {
  '1': <CarIcon />,
  '2': <HouseLineIcon />,
  '4': <WrenchIcon />,
  '5': <BriefcaseIcon />
};

interface JobTypesFormProps {
  submit: (data: Record<string, boolean>) => void;
  isLoading: boolean;
}

const JobTypesForm: FC<JobTypesFormProps> = (props) => {
  const { data } = useQuery<{ data: { JobTypes: JobType[] } }>({
    queryKey: ['signupGeneralData'],
    queryFn: authService.getSignupGeneralData
  });

  const { register, handleSubmit, setValue, watch } = useForm<{
    jobTypes: Record<string, boolean>;
  }>({
    defaultValues: {
      jobTypes: {}
    }
  });

  const parents = data?.data.JobTypes.filter((jobType) => !jobType.ParentId) || [];

  const prepareParentAndChild = useMemo(() => {
    const jobTypes = data?.data.JobTypes || [];
    const ht = {} as Record<string, (string | number)[]>;
    for (const jobType of parents) {
      const parentId = jobType.JobTypeId;
      ht[parentId] = [];
      for (const jT of jobTypes) {
        if (jT.ParentId === parentId) ht[parentId].push(jT.JobTypeId);
      }
    }
    return ht;
  }, [data, parents]);

  const checkAll = (parentId: number, checked: boolean) => {
    const ids = prepareParentAndChild[parentId];
    ids.forEach((id) => {
      setValue(`jobTypes._${id}`, checked);
    });
  };

  const jobTypesSelectedCount = Object.values(watch('jobTypes')).filter(Boolean).length;

  const selectedCount = useMemo(() => {
    return jobTypesSelectedCount
      ? `${jobTypesSelectedCount} Skill Selected`
      : 'Please select at least one skill';
  }, [jobTypesSelectedCount]);

  const onSubmit = handleSubmit((data) => {
    props.submit(data.jobTypes);
  });

  return (
    <div className="Jobtypes-form">
      <div className="Jobtypes-form-content">
        <div className="mb-4">
          <div className="d-flex justify-center mb-4">
            <img src={jobtypes} alt="logo" />{' '}
          </div>
          <h4 className="font-lg fw-700 text-center mb-1 Jobtypes-form-title">
            Hi Albert, what type of jobs should we send you?
          </h4>
          <p className="text-secondary text-center">Let us know what you’re good at</p>
        </div>
        <div className="d-flex direction-column gap-2">
          {parents.map((parent) => {
            return (
              <Accordion key={parent.JobTypeId}>
                <AccordionSummary
                  startIcon={jobTypesIconMap[parent.JobTypeId]}
                  expandIcon={<CaretRightIcon />}>
                  {parent.Name}
                </AccordionSummary>
                <AccordionBody>
                  <ul>
                    <li className="d-flex align-center space-between">
                      <p>Mark All</p>
                      <Checkbox
                        {...register(`jobTypes._${parent.JobTypeId}`, {
                          onChange: (e) => {
                            checkAll(parent.JobTypeId, e.target.checked);
                            return e;
                          }
                        })}
                      />
                    </li>
                    {data?.data.JobTypes.map((jobType) => {
                      if (parent.JobTypeId === jobType.ParentId) {
                        return (
                          <li
                            className="d-flex align-center space-between pl-1"
                            key={jobType.JobTypeId}>
                            <p>{jobType.Name}</p>
                            <Checkbox {...register(`jobTypes._${jobType.JobTypeId}`)} />
                          </li>
                        );
                      }
                    })}
                  </ul>
                </AccordionBody>
              </Accordion>
            );
          })}
        </div>
      </div>
      <Divider />
      <div className="p-2">
        <p className="text-center mb-2 text-secondary">{selectedCount}</p>
        <Button
          fullWidth
          disabled={jobTypesSelectedCount === 0 || props.isLoading}
          onClick={onSubmit}
          loading={props.isLoading}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default memo(JobTypesForm);
