import './SignupPage.scss';
import 'react-phone-input-2/lib/style.css';
import '../../styles/phone-input.scss';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import SignupForm from './components/SignupForm/SignupForm';
import JobTypesForm from './components/JobTypesForm/JobTypesForm';

import { imagesService } from 'services/imagesService';
import { authService } from 'services';

export interface SignupData {
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
  companyNumber: string;
  address: string;
  yearsOfExpirience: string;
  driverLicense: FileList;
  jobTypes: Record<string, boolean>;
}

const formatJobTypes = (jobTypes: Record<string, boolean>) => {
  return Object.entries(jobTypes)
    .filter((jT) => jT[1])
    .map((jT) => jT[0].replace(/_/, ''))
    .join(', ');
};

const SignupPage = () => {
  const [step, setStep] = useState<'1' | '2'>('1');
  const [signupData, setSignupData] = useState({} as SignupData);
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationKey: ['signup'],
    mutationFn: async (formData: SignupData) => {
      try {
        const { driverLicense, jobTypes, ...rest } = formData;
        const fomatedJobTypes = formatJobTypes(jobTypes);
        const { data: imgData } = await imagesService.uploadDriverLicense(driverLicense[0]);
        if (imgData.success !== 'true') {
          console.log('error');
          return;
        }
        const { data: signupData } = await authService.signup({
          ...rest,
          driverLicense: imgData.url,
          jobTypes: fomatedJobTypes
        });
        if (signupData.success !== 'true') {
          console.log('error');
          return;
        }
        navigate(`/entercode?phone=${rest.phone}&signup=true`);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const setData = useCallback((data: Omit<SignupData, 'jobTypes'>) => {
    setSignupData((prev) => ({ ...prev, ...data }));
    setStep('2');
  }, []);

  const submit = useCallback(
    (data: SignupData['jobTypes']) => {
      mutate({ ...signupData, jobTypes: data });
    },
    [signupData]
  );

  return (
    <>
      {step === '1' && <SignupForm setData={setData} />}
      {step === '2' && <JobTypesForm submit={submit} isLoading={isLoading} />}
    </>
  );
};

export default SignupPage;
