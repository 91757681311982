import React, { forwardRef } from 'react';

import { SuccessIcon } from 'assets';
import './Switcher.scss';

type ISwitcherProps = {
  stateOn: string;
  stateOff: string;
} & JSX.IntrinsicElements['input'];

const Switcher = forwardRef<HTMLInputElement, ISwitcherProps>((props, ref) => {
  const { stateOn, stateOff, ...restProps } = props;

  return (
    <label htmlFor="switcher" className="Switcher">
      <input id="switcher" {...restProps} type="checkbox" hidden ref={ref} />
      <div className="Switcher-state Switcher-state-off">
        <span className="icon">
          <SuccessIcon />
        </span>
        {stateOff}{' '}
      </div>
      <div className="Switcher-state Switcher-state-on">
        <span className="icon">
          <SuccessIcon />
        </span>
        {stateOn}
      </div>
    </label>
  );
});

Switcher.displayName = 'Switcher';

export default Switcher;
