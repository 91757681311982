import { RouteObject } from 'react-router-dom';

import { AuthGuard } from './AuthGuard';
import { PublicGuard } from './PublicGuard';

import EnterCodePage from 'pages/EnterCodePage';
import JobsMapPage from 'pages/JobsMapPage';
import LoginPage from 'pages/LoginPage';
import ProfilePage from 'pages/ProfilePage';
import SignupPage from 'pages/SignupPage';
import PaymentRequestPage from 'pages/PaymentRequestPage';
import JobDetailsPage from 'pages/JobDetailsPage';
import CloseJobSubtechPage from 'pages/CloseJobSubtechPage';
import ChatPage from 'pages/ChatPage';
import CloseJobTechPage from 'pages/CloseJobTechPage';
import CancelJobSubtechPage from 'pages/CancelJobSubtechPage';
import CancelJobTechPage from 'pages/CancelJobTechPage';
import OpenSmsPage from 'pages/OpenSmsPage';
import JobHistoryPage from 'pages/JobHistoryPage';
import { LocationServiceProvider } from 'contexts/LocationContext';
import SendLocation from 'pages/SendLocation';
import ChatListPage from 'pages/ChatListPage';

export const routes: RouteObject[] = [
  { path: '/cancel/:sjId/:techToken', element: <CancelJobTechPage /> },
  { path: '/opensms_tech/:sjId/:techToken', element: <OpenSmsPage /> },
  { path: '/cancel/:sjId/:techToken', element: <CancelJobTechPage /> },
  {
    path: '/',
    element: <AuthGuard />,
    children: [
      { path: '', element: <LoginPage /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/signup', element: <SignupPage /> },
      { path: '/entercode', element: <EnterCodePage /> },
      { path: '*', element: <LoginPage /> }
    ]
  },
  {
    path: '/',
    element: <PublicGuard />,
    children: [
      { path: '', element: <JobsMapPage /> },
      { path: '/jobs-map', element: <JobsMapPage /> },
      { path: '/profile', element: <ProfilePage /> },
      { path: '/payment-request/:sjId', element: <PaymentRequestPage /> },
      { path: '/chat/:sk', element: <ChatPage /> },
      { path: '/chatlist', element: <ChatListPage /> },
      { path: '/job-details/:sjId', element: <JobDetailsPage /> },
      { path: '/close/:sjId', element: <CloseJobSubtechPage /> },
      { path: '/cancel/:sjId', element: <CancelJobSubtechPage /> },
      { path: '/jobs-history', element: <JobHistoryPage /> },
      { path: '*', element: <JobsMapPage /> }
    ]
  },
  {
    path: '/send-location',
    element: (
      <LocationServiceProvider>
        <SendLocation />{' '}
      </LocationServiceProvider>
    )
  },
  {
    path: '/close/:sjId/:techToken',
    element: (
      <LocationServiceProvider>
        <CloseJobTechPage />
      </LocationServiceProvider>
    )
  },
  { path: '/cancel/:sjId/:techToken', element: <CancelJobTechPage /> },
  { path: '/opensms_tech/:sjId/:techToken', element: <OpenSmsPage /> }
];
