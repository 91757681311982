import React, { forwardRef, useId } from 'react';

import Ripple from '../Ripple';
import './Checkbox.scss';

const Checkbox = forwardRef<HTMLInputElement, React.HTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const checkboxId = useId();

    return (
      <label htmlFor={checkboxId} className="checkbox-label">
        <p className="checkbox-custom-label"></p>
        <input id={checkboxId} type="checkbox" hidden {...props} ref={ref} />
        <div className="checkbox-checkmark"></div>
        <Ripple />
      </label>
    );
  }
);
Checkbox.displayName = 'Checkbox';

export default Checkbox;
