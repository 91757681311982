import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

import { Button } from 'components/ui';
import { userService } from 'services/userService';
import './DeleteModal.scss';

interface DeleteAccount {
  onClose: () => void;
}

const DeleteModal: FC<DeleteAccount> = (props) => {
  const { onClose } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationFn: async () => {
      try {
        await userService.deactivateAccount();
        enqueueSnackbar(
          'You have been notified that the account you were using has been deleted.',
          {
            variant: 'success'
          }
        );
        onClose();
      } catch (error) {
        console.log(error);
      }
    }
  });

  return (
    <div className="DeleteModal">
      <p className="fw-500 font-lg mb-2 text-center">Are you sure you want to delete account?</p>
      <div className="d-flex gap-2 w-100">
        <Button variant="outlined" style={{ flexGrow: 1 }} onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" variant="outlined" style={{ flexGrow: 1 }} onClick={mutate}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default DeleteModal;
