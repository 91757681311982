import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGeneralData } from 'contexts/GeneralDataContext';
import CancelJob from 'components/CancelJob';
import { jobsService } from 'services';

const CancelJobSubtechPage = () => {
  const params = useParams<{ sjId: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { generalData } = useGeneralData();

  const { data, isLoading: isJobLoading } = useQuery({
    queryKey: ['job', params.sjId],
    queryFn: () => jobsService.getJob(params.sjId || ''),
    keepPreviousData: true
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['cancel', params.sjId],
    mutationFn: async (values: { reason: string; comments: string }) => {
      if (!data?.data) return;
      const payload = {
        ...values,
        reasonId: values.reason,
        sjId: data.data.sjId,
        techId: data.data.techId,
        LL: `${data.data.Latitude}, ${data?.data?.Longitude}`,
        jobId: data?.data?.jobId
      };
      try {
        const resp = await jobsService.cancelJob(payload);
        if (resp.data.success === 'true') {
          enqueueSnackbar('Job has been canceled', { variant: 'success' });
          navigate(`/job-details/${params.sjId}`, { replace: true });
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  return (
    <CancelJob
      submitFn={mutate}
      isCancelLoading={isLoading}
      job={data?.data}
      isJobLoading={isJobLoading}
      cancelReasons={generalData?.CancelReasons}
    />
  );
};

export default CancelJobSubtechPage;
