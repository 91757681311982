import './HeaderWithTitle.scss';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { ChatCircleDots, Menu } from 'assets';
import { useSidebar } from 'contexts/SidebarContext';
import { chatService } from 'services/chatService';

interface HeaderWithTitleProps {
  jobsCount?: number;
}

const HeaderWithTitle: FC<HeaderWithTitleProps> = (props) => {
  const { openSidebar } = useSidebar();
  const { data } = useQuery({
    queryKey: ['unReadcount'],
    queryFn: chatService.getUnreadCount,
    refetchInterval: 10000
  });
  return (
    <div className="HeaderWithTitle">
      <button className="menu" onClick={openSidebar}>
        <Menu></Menu>
      </button>
      <div className="title">{props.jobsCount ? `${props.jobsCount} Jobs` : 'No jobs'}</div>
      <Link className="chat-info link" to="/chatlist">
        {data?.data?.unReadCount ? (
          <span className="chat-info__counter">{data?.data?.unReadCount}</span>
        ) : null}
        <ChatCircleDots></ChatCircleDots>
      </Link>
    </div>
  );
};

export default HeaderWithTitle;
