import React, { FC } from 'react';

import { CommentsIcon, UserIcon } from 'assets';
import './FollowupJobAditionalInfo.scss';
interface FollowupJobAditionalInfo {
  jobInfo: {
    comments: string | null;
    preComments: string;
  };
}

const FollowupJobAditionalInfo: FC<FollowupJobAditionalInfo> = (props) => {
  const { jobInfo } = props;

  return (
    <div>
      {jobInfo.comments && (
        <div className="d-flex gap-1 align-start mb-2">
          <UserIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Comments</p>
            <p>{jobInfo.comments}</p>
          </div>
        </div>
      )}
      {jobInfo.preComments && (
        <div className="d-flex gap-1 align-start mb-2">
          <CommentsIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Pre Comments</p>
            <p>{jobInfo.preComments}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FollowupJobAditionalInfo;
