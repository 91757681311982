import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';

import Button from '../Button';

import { FileArrowUpIcon, PencilIcon } from 'assets';
import './FileInput.scss';

type FileInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  placeholder?: string;
  errorText?: string;
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const { label, onChange, placeholder, errorText, className, ...restProps } = props;

  const [isFileExist, setIsFileExist] = useState(false);
  const [fileName, setFileName] = useState('');
  const classes = classNames({
    'file-input-container': true
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] as File;
    if (file) {
      setIsFileExist(true);
      setFileName(file.name);
    }
    onChange?.(e);
  };

  return (
    <div className={`${classes} ${className}`}>
      <p className="file-input-custom-label">{label}</p>
      <label htmlFor="id" className="file-input-label">
        <FileArrowUpIcon />
        <p className="file-input-placeholder">{fileName ? fileName : placeholder}</p>
        {isFileExist && (
          <Button
            as="label"
            htmlFor="id"
            variant="outlined"
            size="small"
            style={{ width: '32px', height: '32px' }}
            className="file-input-edit">
            <PencilIcon width="16px" height="16px" />
          </Button>
        )}
      </label>
      <input id="id" type="file" hidden {...restProps} onChange={handleChange} ref={ref} />
      <p className="file-input-error">{errorText}</p>
    </div>
  );
});

FileInput.displayName = 'FileInput';

export default FileInput;
