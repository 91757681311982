import React, { FC } from 'react';

import { CommentsIcon, UserIcon } from 'assets';
import { useGeneralData } from 'contexts/GeneralDataContext';
import './ClosedJobAdditionalInfo.scss';

interface ClosedJobAdditionalInfo {
  jobInfo: {
    totalCost: number | null;
    partsCost: number | null;
    paymentMethod: number | null;
    techShare: number | null;
    comments: string | null;
    preComments: string;
  };
}

const ClosedJobAdditionalInfo: FC<ClosedJobAdditionalInfo> = (props) => {
  const { jobInfo } = props;
  const { generalData } = useGeneralData();
  const paymentMethod = generalData?.CustomerPayMethods.find(
    (method) => method.Id === jobInfo.paymentMethod
  );
  return (
    <>
      {' '}
      <div className="d-flex gap-1 align-start mb-2">
        <UserIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Total cost</p>
          <p>$ {jobInfo.totalCost}</p>
        </div>
      </div>
      <div className="d-flex gap-1 align-start mb-2">
        <UserIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Parts cost</p>
          <p>$ {jobInfo.partsCost || 0}</p>
        </div>
      </div>
      <div className="d-flex gap-1 align-start mb-2">
        <UserIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Your share</p>
          <p>$ {jobInfo.techShare || 0}</p>
        </div>
      </div>
      <div className="d-flex gap-1 align-start mb-2">
        <UserIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Payment Method</p>
          <p>{paymentMethod?.Name || ''}</p>
        </div>
      </div>
      {jobInfo.comments && (
        <div className="d-flex gap-1 align-start mb-2">
          <UserIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Comments</p>
            <p>{jobInfo.comments}</p>
          </div>
        </div>
      )}
      {jobInfo.preComments && (
        <div className="d-flex gap-1 align-start mb-2">
          <CommentsIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Pre Comments</p>
            <p>{jobInfo.preComments}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ClosedJobAdditionalInfo;
