import React, { FC } from 'react';

import { useGeneralData } from 'contexts/GeneralDataContext';
import { ShareStatusesNames } from 'types/generalData';
import './JobStatus.scss';
import { shareStatusIcons } from 'helpers/shareStatusesIcon';

interface JobStatusProps {
  status: ShareStatusesNames;
}

const JobStatus: FC<JobStatusProps> = (props) => {
  const { status } = props;
  const { generalData } = useGeneralData();

  const statusName = generalData?.ShareStatuses.find((stat) => stat.Id === status)?.Name;
  const Icon = shareStatusIcons[status];

  return (
    <div className="JobStatus">
      <Icon />
      <p>Shared: {statusName}</p>
    </div>
  );
};

export default JobStatus;
