import { useMutation } from '@tanstack/react-query';
import { FC, useEffect } from 'react';

import ActivityLogItem from './AcivityLogItem';

import { jobsService } from 'services';
import { CircularProgress } from 'components/ui';
import './ActivityLog.scss';

interface ActivityLogProps {
  jobId: number;
}

const ActivityLog: FC<ActivityLogProps> = (props) => {
  const { jobId } = props;

  const { data, mutate, isLoading } = useMutation({
    mutationFn: () => jobsService.getJobAcivityLog(jobId)
  });

  useEffect(() => {
    mutate();
  }, []);

  return (
    <div className="ActivityLog">
      {isLoading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {data?.data.history.map((item, i) => {
            return <ActivityLogItem key={i} item={item} />;
          })}
        </>
      )}
    </div>
  );
};

export default ActivityLog;
