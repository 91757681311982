import classNames from 'classnames';
import { forwardRef } from 'react';
import './Select.scss';

export type SelectProp = {
  label?: string;
  id?: string;
  error?: string | undefined;
  fullWidth?: boolean;
  options?: { value: number; title: string }[];
} & JSX.IntrinsicElements['select'];

const Select = forwardRef<HTMLSelectElement, SelectProp>((props, ref) => {
  const { id, label, fullWidth = false, error, className, options, ...restProps } = props;

  const selectClass = classNames({
    'select-container': true,
    'select-error': !!error,
    'select-fullWidth': fullWidth
  });

  return (
    <div className={`${selectClass} ${className}`}>
      <label className="select-label" htmlFor={id}>
        {label}
      </label>
      <div className="select-wrapper">
        <select id={id} {...restProps} ref={ref} className="select-field">
          <option value="DEFAULT" disabled>
            Select your option
          </option>
          {options?.map((option) => (
            <option value={option.value} key={option.value}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
      <p className="select-error-message">{error}</p>
    </div>
  );
});

Select.displayName = 'Select';

export default Select;
