export interface GeneralData {
  CompanyName: string;
  ShareStatuses: ShareStatus[];
  JobStatuses: JobStatus[];
  CustomerPayMethods: PaymentMethod[];
  PaymentTypes: IPaymentType[];
  CancelReasons: CancelReason[];
  MyPhoneNumber: string;
  MyData: {
    Id: number;
    TechId: number;
    NickName: string;
    Phone: string;
    ShareCount: number;
    Comments: string;
    Share: number;
    ReminderCalls: number;
    Email: string;
    Token: string;
    DeleteDate: null;
    Balance: number;
    WorkingMethod: number;
    AbsAdditionalCostPerJob: null;
    SecretKey: string;
    LastSeen: string;
    LL: {
      Geography: {
        CoordinateSystemId: 4326;
        WellKnownText: 'POINT (14.4448255 50.0640489)';
      };
    };
    NightShare: null;
  };
  sk: string;
  unReadCount: number;
  SkipReasons: ISkipReason[];
}

export interface ISkipReason {
  Id: number;
  Reason: string;
}

export enum ShareStatusesNames {
  Sent = 1,
  Open = 2,
  Approved = 3,
  Claim = 5,
  Closed = 6,
  Canceled = 8,
  RequestToFollowup = 10,
  Completed = 11,
  InReview = 12,
  PostReview = 13
}

export enum PaymentMethods {
  CreditCard = 1,
  Cash = 2
}

export enum PORStatuses {
  Declined = -1,
  OnReview = 0,
  Approwed = 1
}

enum PaymentType {
  FixedPrice = 1,
  RevenueShare = 2
}

interface ShareStatus {
  Id: number;
  Name: string;
}

interface JobStatus {
  Id: number;
  Status: string;
}

interface PaymentMethod {
  Id: number;
  Name: string;
  Default: boolean;
}

interface IPaymentType {
  Id: number;
  Name: string;
}

interface CancelReason {
  Id: number;
  Reason: string;
}
