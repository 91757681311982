import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';
import { GeneralDataProvider } from 'contexts/GeneralDataContext';
import { LocationServiceProvider } from 'contexts/LocationContext';
import { SidebarContextProvider } from 'contexts/SidebarContext';

export const PublicGuard = () => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  return (
    <GeneralDataProvider>
      <SidebarContextProvider>
        <LocationServiceProvider>
          <Outlet />
        </LocationServiceProvider>
      </SidebarContextProvider>
    </GeneralDataProvider>
  );
};
