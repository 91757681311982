import classNames from 'classnames';
import { FC, ReactNode, memo, useRef, useMemo } from 'react';
import { CSSTransition, Transition } from 'react-transition-group';

import Portal from '../Portal';

import { useIsFirstRender } from 'hooks/isFirstRender';
import './Drawer.scss';
import { usePrevValue } from 'hooks/usePrevValue';
interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode | ReactNode[];
  anchor?: 'left' | 'right' | 'top' | 'bottom';
  overlayTransparent?: boolean;
}
const Drawer: FC<IModalProps> = ({
  isOpen,
  onClose,
  children,
  anchor = 'left',
  overlayTransparent = false
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const isfirstRender = useIsFirstRender();

  const animationClasses = classNames({
    'Drawer-left': anchor === 'left',
    'Drawer-right': anchor === 'right',
    'Drawer-top': anchor === 'top',
    'Drawer-bottom': anchor === 'bottom'
  });

  const defaultActiveClass = classNames({
    'Drawer-overlay-transparent': overlayTransparent
  });

  return (
    <Portal>
      <CSSTransition
        in={isOpen}
        appear={true}
        nodeRef={ref}
        timeout={300}
        classNames={animationClasses}
        unmountOnExit
        mountOnEnter>
        <div className={`Drawer ${defaultActiveClass}`} ref={ref}>
          <div className="Drawer-overlay" onClick={onClose}>
            <div className="Drawer-content" onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default memo(Drawer);
