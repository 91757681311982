import { FC, memo, useEffect, useRef } from 'react';
import deepEqual from 'deep-equal';

import { useLocationService } from 'contexts/LocationContext';
import { mapStyles } from 'helpers/mapStyles';
import { JobListItem } from 'types/jobs';
import { useCustomCompare } from 'hooks/useCustomCompare';

import './JobsMap.scss';

interface JobsMapProps {
  jobs?: JobListItem[];
  setCurrentJob: (sjId: number) => void;
}

const JobsMap: FC<JobsMapProps> = (props) => {
  const { jobs, setCurrentJob } = props;
  const { currentLocation } = useLocationService();

  const map = useRef<google.maps.Map | null>(null);
  const myMarker = useRef<google.maps.Marker | null>(null);
  const markers = useRef<google.maps.Marker[] | []>([]);

  useEffect(() => {
    initMap();
  }, [currentLocation]);

  useEffect(() => {
    if (jobs?.length) {
      jobs.forEach((job) => {
        createJobMarker(job, map.current);
      });
      map.current?.panTo(new google.maps.LatLng(jobs[0].Latitude, jobs[0].Longitude));
    }

    return () => {
      if (jobs?.length) {
        markers.current.forEach((marker) => {
          marker.setMap(null);
        });
        markers.current = [];
      }
    };
  }, [useCustomCompare(jobs, deepEqual), map.current]);

  function initMap() {
    const myLatLng = { lat: currentLocation.lat, lng: currentLocation.lng };
    map.current = new google.maps.Map(document.getElementById('map')!, {
      zoom: 10,
      center: myLatLng,
      styles: mapStyles
    });
    createMyMarker(myLatLng, map.current);
  }

  function createJobMarker(job: JobListItem, map: google.maps.Map | null) {
    const { Latitude, Longitude } = job;

    const marker = new google.maps.Marker({
      position: { lat: Latitude, lng: Longitude },
      map,
      icon: {
        url: `https://qlfiles.s3.us-east-1.amazonaws.com/shareApp/map-markers/${job.ShareStatus}.png`,
        scaledSize: new google.maps.Size(70, 70) // scaled size
      }
    });
    marker.addListener('click', () => {
      setCurrentJob(job.ShareJobId);
    });
    markers.current = [...markers.current, marker];
  }

  function createMyMarker(myLatLng: { lat: number; lng: number }, map: google.maps.Map | null) {
    myMarker.current = new google.maps.Marker({
      position: myLatLng,
      map,
      icon: 'http://labs.google.com/ridefinder/images/mm_20_purple.png' // we can use just url
    });
  }

  return <div className="jobs-map" id="map"></div>;
};

export default memo(JobsMap);
