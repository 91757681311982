import { AxiosInstance } from 'axios';

import { axiosInst } from 'axiosInst';
import { GeneralData } from 'types/generalData';

class GeneralService {
  constructor(private axiosInst: AxiosInstance) {
    this.axiosInst = axiosInst;
  }

  getGeneralData() {
    return axiosInst.get<{ GeneralData: GeneralData }>(`/api/share/generalData`);
  }
}

export const generalService = new GeneralService(axiosInst);
