import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import { mapStyles } from 'helpers/mapStyles';
import { CircularProgress, Drawer } from 'components/ui';
import { CircleSuccessIcon, CrossIcon } from 'assets';
import { config } from 'config';

import './SendLocation.scss';

const DEFAULT_LAT_LNG = { lat: 40.7050362, lng: -73.9783077 };

interface ISendLocation {
  address: string;
  zipCode: string;
  LL: string;
}

const sendLoaction = async (args: ISendLocation) => {
  const urlParams = new URLSearchParams(window.location.search);
  const path = '/api/AddressRequest/save';
  const formData = new FormData();
  const data = {
    items: [
      {
        guid: urlParams.get('gid') || '80ed1d24-1fce-42ab-849b-013152934884',
        address: args.address,
        zipCode: args.zipCode,
        LL: args.LL
      }
    ]
  };
  formData.append('data', JSON.stringify(data));

  return axios.post(config.apiLink + path, new URLSearchParams(formData as any), {
    headers: { Token: '' }
  });
};

const SendLocation = () => {
  const map = useRef<google.maps.Map | null>(null);
  const myMarker = useRef<google.maps.Marker | null>(null);
  const circle = useRef<google.maps.Circle | null>(null);
  const reqAnimationId = useRef<number | null>(null);

  const [isOpen, setIsOpen] = useState(true);
  const [currentLatLng, setCurrentLatLng] = useState(DEFAULT_LAT_LNG);
  const [status, setStatus] = useState<'init' | 'sending' | 'sent' | 'error' | 'serverError'>(
    'init'
  );
  const [address, setAddress] = useState('');

  const { mutate } = useMutation({
    mutationFn: async (data: ISendLocation) => {
      try {
        const res = await sendLoaction(data);
        if (res.data.success === 'true') {
          setStatus('sent');
          return;
        }
        setStatus('serverError');
      } catch (error) {
        setStatus('serverError');
      }
    }
  });

  useEffect(() => {
    initMap();
    shareLocation();
  }, []);

  useEffect(() => {
    if (!map.current) return;
    if (currentLatLng === DEFAULT_LAT_LNG) return;

    createMyMarker(currentLatLng, map.current);
    map.current.panTo(new google.maps.LatLng(currentLatLng.lat, currentLatLng.lng));
    map.current.setZoom(13);
  }, [currentLatLng]);

  function initMap() {
    map.current = new google.maps.Map(document.getElementById('map')!, {
      zoom: 2,
      center: currentLatLng,
      styles: mapStyles
    });
    createMyMarker(currentLatLng, map.current);
    createCircle(currentLatLng, map.current);
  }

  function createMyMarker(myLatLng: { lat: number; lng: number }, map: google.maps.Map | null) {
    if (myMarker.current) {
      myMarker.current.setMap(null);
    }

    if (circle.current && reqAnimationId.current) {
      circle.current.setMap(null);
      cancelAnimationFrame(reqAnimationId.current);
    }

    myMarker.current = new google.maps.Marker({
      position: myLatLng,
      map,
      icon: 'http://labs.google.com/ridefinder/images/mm_20_purple.png' // we can use just url
    });
  }

  function shareLocation() {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const myLatLng = { lat: lat, lng: lng };
        const geocoder = new google.maps.Geocoder();
        setCurrentLatLng(myLatLng);
        geocoder.geocode({ location: myLatLng }, (results, status) => {
          setStatus('sending');
          if (status === google.maps.GeocoderStatus.OK) {
            const address = results?.[0].formatted_address.split(',').reverse().join() || '';
            const addressArr = results?.[0].address_components;
            const zipCode = addressArr?.[addressArr.length - 1].long_name || '-1';
            setAddress(address);
            mutate({ address, zipCode, LL: `${lat},${lng}` });

            return;
          }
          setStatus('error');
        });
      },
      (ignored) => {
        setStatus('error');
      }
    );
  }

  function createCircle(center: { lat: number; lng: number }, map: google.maps.Map) {
    const _radius = 3500000;
    const rMin = _radius / 5;
    const rMax = _radius;
    const circleOption = {
      center: center,
      fillColor: '#3878c7',
      fillOpacity: 0.6,
      map: map,
      radius: _radius,
      strokeColor: '#3878c7',
      strokeOpacity: 1,
      strokeWeight: 0.5
    };
    circle.current = new google.maps.Circle(circleOption);
    let direction = 9000;

    const animateCycle = () => {
      if (circle.current) {
        const radius = circle.current.getRadius();

        if (radius > rMax || radius < rMin) {
          direction *= -1;
        }
        const _par = radius / _radius - 0.1;

        circleOption.radius = radius + direction * 4;
        circleOption.fillOpacity = 0.4 * _par;
      }
      circle.current?.setOptions(circleOption);
      reqAnimationId.current = requestAnimationFrame(animateCycle);
    };

    reqAnimationId.current = requestAnimationFrame(animateCycle);
  }

  const drawerContent = useMemo(() => {
    switch (status) {
      case 'init':
        return (
          <div className="stack align-center gap-2">
            <p className="font-lg text-center">Wait please, searching...</p>
            <CircularProgress />
          </div>
        );
      case 'error':
        return (
          <div className="stack align-center gap-2">
            <p className="font-lg text-center">
              You have to enable geolacation for this website in browser settings. Please, enable
              and reload page.
            </p>
            <CrossIcon width={45} height={45} />
          </div>
        );
      case 'sending':
        return (
          <div className="stack align-center gap-2">
            <p className="font-lg text-center">Sending location...</p>
            <CircularProgress />
          </div>
        );
      case 'sent':
        return (
          <div className="stack align-center gap-2">
            <p className="font-lg text-center">{address}</p>
            <CircleSuccessIcon width={45} height={45} />
          </div>
        );
      case 'serverError':
        return (
          <div className="stack align-center gap-2">
            <p className="font-lg text-center">Something went wrong. Please reload page.</p>
            <CrossIcon width={45} height={45} />
          </div>
        );
    }
  }, [status, address]);

  return (
    <>
      <div className="page-wrapper">
        <div className="jobs-map" id="map"></div>
      </div>
      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} overlayTransparent anchor="bottom">
        <div className="searching-location">{drawerContent}</div>
      </Drawer>
    </>
  );
};

export default SendLocation;
