/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import CircularProgress from '../CircularProgress';
import Ripple from '../Ripple';
import './Button.scss';

const ButtonLoader = () => {
  return (
    <div className="Button-loader">
      <CircularProgress style={{ height: '30px', width: '30px' }} />
    </div>
  );
};

type ButtonTypes = 'button' | 'a';

type ButtonProps<Type extends ButtonTypes | React.ComponentType<any>> = {
  round?: boolean;
  hasRipple?: boolean;
  as?: Type;
  color: 'primary' | 'secondary' | 'error' | 'transparent';
  variant?: 'outlined' | 'contained' | 'text';
  size?: 'small' | 'medium' | 'large';
  width?: string;
  height?: string;
  fullWidth?: boolean;
  loading?: boolean;
} & ButtonAditionalPropsType<Type>;

type ButtonAditionalPropsType<T extends ButtonTypes | React.ComponentType> =
  T extends keyof JSX.IntrinsicElements
    ? JSX.IntrinsicElements[T]
    : React.ComponentPropsWithoutRef<T>;

const Button = forwardRef(
  <Type extends ButtonTypes | React.ComponentType<any> = 'button'>(
    props: ButtonProps<Type>,
    ref: React.ComponentPropsWithRef<Type>['ref']
  ) => {
    const {
      as,
      children,
      width,
      height,
      round = false,
      color = 'primary',
      variant = 'contained',
      size = 'medium',
      hasRipple = true,
      fullWidth,
      className,
      loading,
      ...rest
    } = props;
    const Component = as || 'button';
    const btnClasses = classNames({
      btn: true,
      'btn--outlined': variant === 'outlined',
      'btn--contained': variant === 'contained',
      'btn--small': size === 'small',
      'btn--medium': size === 'medium',
      'btn--large': size === 'large',
      'btn-text': variant === 'text',
      'btn--round': round,
      'btn--primary': color === 'primary',
      'btn--error': color === 'error',
      'btn--fullwidth': fullWidth
    });

    return (
      <Component
        ref={ref}
        className={`${btnClasses} ${className}`}
        style={{ width: width, height: height }}
        {...rest}>
        {children}
        {hasRipple ? <Ripple /> : null}
        {loading && <ButtonLoader />}
      </Component>
    );
  }
);

Button.displayName = 'Button';

export default Button;
