import React, { FC, useRef } from 'react';
import './Accordion.scss';
import { CSSTransition } from 'react-transition-group';

import { useAccordionContext } from './AccordionContext';
interface IAcrodionSummary {
  startIcon: React.ReactNode;
  expandIcon: React.ReactNode;
  children: React.ReactNode;
}
const AccordionSummary: FC<IAcrodionSummary> = (props) => {
  const { startIcon, expandIcon, children } = props;
  const { expanded, toogleExpanded } = useAccordionContext();

  const expandIconRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="accordion-summary" onClick={toogleExpanded}>
      <div className="accordion-summary-starticon"> {startIcon}</div>
      <div className="accordion-summary-children">{children}</div>
      <CSSTransition nodeRef={expandIconRef} in={expanded} timeout={250} classNames="icon-expanded">
        <div className="accordion-summary-expandicon" ref={expandIconRef}>
          {expandIcon}
        </div>
      </CSSTransition>
    </div>
  );
};

export default AccordionSummary;
