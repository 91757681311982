import { AxiosInstance } from 'axios';

import { axiosInst } from 'axiosInst';
import { GetAllChatsResponse, GetAllMessagesResp } from 'types/chat';

class ChatService {
  constructor(private axiosInst: AxiosInstance) {
    this.axiosInst = axiosInst;
  }

  _preparePayload = (items: Record<any, any>) => {
    const formData = new FormData();
    const data = {
      items: [items]
    };
    formData.append('data', JSON.stringify(data));
    return new URLSearchParams(formData as any);
  };

  async getAllMessages(args: { sk: string }) {
    const payload = this._preparePayload(args);
    return axiosInst.post<GetAllMessagesResp>('/api/getChat', payload);
  }
  async getChat(args: { sk: string }) {
    const payload = this._preparePayload(args);
    return axiosInst.post<GetAllMessagesResp>('/api/getChat', payload);
  }
  async getChatList() {
    return axiosInst.get<GetAllChatsResponse>('/api/GetChatList/');
  }

  sendMessage = async (args: { sk: string; msg: string }) => {
    const payload = this._preparePayload(args);
    return this.axiosInst.post('/api/Chat', payload);
  };

  async getUnreadCount() {
    return axiosInst.get('/api/GetUnreadCount/');
  }
}

export const chatService = new ChatService(axiosInst);
