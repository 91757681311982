import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';

export const AuthGuard = () => {
  const { isAuth } = useAuth();

  if (isAuth) {
    return <Navigate to="/jobs-map" />;
  }
  return <Outlet />;
};
