import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';

import SkipJobReasons from '../SkipJobReasons/SkipJobReasons';

import { queryClient } from 'App';
import { MoneyIcon } from 'assets';
import JobCard from 'components/JobCard';
import { Button, Drawer } from 'components/ui';
import { formatPrice } from 'helpers/formatPrice';
import { jobsService } from 'services';
import { JobListItem } from 'types/jobs';

import './NewJob.scss';

interface NewJobProps {
  job: JobListItem;
  onClose: () => void;
}

const NewJob: FC<NewJobProps> = (props) => {
  const { job, onClose } = props;
  const [isOpen, setIsOpen] = useState(false);

  const { mutate, isLoading: isLoadingDecision } = useMutation({
    mutationFn: (decision: number) => jobsService.makeDecision(job.ShareJobId, decision),
    onSuccess: () => {
      queryClient.refetchQueries(['allJobs']);
      onClose();
    }
  });

  const { isLoading } = useQuery({
    queryKey: ['markASSeen', job.ShareJobId],
    queryFn: () => jobsService.markAsSeen(job.ShareJobId)
  });

  const skipJob = () => {
    setIsOpen(true);
  };

  const closeDrawes = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <>
      <div className="NewJob">
        <h4 className="text-center font-lg mb-2 fw-700">New Job</h4>
        <JobCard
          className="mb-2"
          job={{
            ...job,
            jobId: job.JobId,
            jobType: job.TypeAndIgnitionKey,
            txt2copy: 'mock text to copy',
            shareStatus: job.ShareStatus,
            city: job.City,
            zipCode: job.Zipcode,
            sjId: job.ShareJobId,
            jobAddress: job.Address,
            Comments: job.Comments,
            preComments: job.PreComments,
            ExternalJobId: job.ExternalJobId
          }}
        />
        <div className="d-flex gap-1 align-start mb-3">
          <MoneyIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Estimated Price</p>
            <p>{formatPrice(job.jobEstimatedPrice)}</p>
          </div>
        </div>
        <div className="d-flex gap-2 mt-2">
          <Button
            style={{ flexGrow: 1 }}
            onClick={() => mutate(3)}
            disabled={isLoadingDecision || isLoading}>
            Approve
          </Button>
          <Button
            variant="outlined"
            style={{ flexGrow: 1 }}
            onClick={skipJob}
            disabled={isLoadingDecision || isLoading}>
            Skip
          </Button>
        </div>
      </div>
      <Drawer anchor="bottom" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SkipJobReasons sjId={job.ShareJobId} onClose={closeDrawes} />
      </Drawer>
    </>
  );
};

export default NewJob;
