import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import Filters from './components/Filters';

import {
  ArrowLeftIcon,
  CurrencyCircleDollarIcon,
  FilterIcon,
  MapPinIcon,
  NavigationArrowIcon
} from 'assets';
import JobCard from 'components/JobCard';
import { Button, Divider, Drawer, PageLoader } from 'components/ui';
import { monthNames } from 'constants/months';
import { createNavLink } from 'helpers/createNavigationLink';
import { formatPrice } from 'helpers/formatPrice';
import { jobsService } from 'services';
import { Filters as FiltersType } from 'services/jobsService';
import { usePrevValue } from 'hooks/usePrevValue';
import './JobHistoryPage.scss';

const formatDate = (dateString: string) => {
  const d = new Date(dateString);
  const month = monthNames[d.getMonth()];
  const day = d.getDate();
  const year = d.getFullYear();
  const time = DateTime.fromISO(dateString).toFormat('hh:mm a');
  return `${month} ${day}, ${year}, ${time}`;
};

const JobHistoryPage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const { mutate, data, isLoading } = useMutation({
    mutationFn: async (newFilters: FiltersType = {}) => {
      const filtered = Object.fromEntries(
        Object.entries(newFilters).filter((entry) => Boolean(entry[1]))
      );
      setParams(filtered);
      const res = await jobsService.getAllJobsWithFilters(filtered);
      setIsInitialLoading(false);
      return res;
    }
  });

  useEffect(() => {
    const defaultFilters = {
      DateFrom: params.get('DateFrom') ?? '',
      DateTo: params.get('DateTo') ?? '',
      ZipCode: params.get('Zip') ?? '',
      Phone: params.get('Phone') ?? '',
      JobId: params.get('JobId') ?? '',
      Status: params.get('Status') ?? ''
    };

    mutate(defaultFilters);
  }, []);

  const jobsFromServer = useMemo(() => {
    return data?.data.jobs.flat();
  }, [data]);

  const prevValue = usePrevValue(jobsFromServer);

  const jobs = useMemo(() => {
    return jobsFromServer || prevValue;
  }, [jobsFromServer, prevValue]);

  const applyFilters = (filters: FiltersType) => {
    mutate(filters);
  };

  if (isLoading && isInitialLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="JobHistoryPage">
        <div className="d-flex gap-1 align-center pt-2 pr-2 pl-2">
          <ArrowLeftIcon className="pointer" onClick={() => navigate('/jobs-map')} />
          <p className="fw-700 font-lg"> Job History</p>
          <Button
            variant="text"
            style={{ gap: '4px', marginLeft: 'auto' }}
            onClick={() => setIsOpen(true)}>
            <FilterIcon />
            Filters
          </Button>
        </div>
        <div className="p-1 pl-2 ">{jobs?.length ?? 0} jobs foud</div>
        <Divider />
        <div className="JobHistoryPage-content">
          <div className="stack gap-1">
            {jobs?.length ? (
              jobs.map((job) => {
                const navigationLink = createNavLink({ lat: job.Latitude, lng: job.Longitude });

                return (
                  <div
                    className="history-job-wrapper"
                    key={job.ShareJobId}
                    style={{ opacity: jobsFromServer ? 1 : 0.5 }}>
                    <div className="mb-1 fw-500">{formatDate(job.CreatedDate)}</div>
                    <JobCard
                      className="mb-1"
                      titleSize="md"
                      withAddres={false}
                      job={{
                        ...job,
                        jobId: job.JobId,
                        jobType: job.TypeAndIgnitionKey,
                        txt2copy: job.Text2Copy,
                        shareStatus: job.ShareStatus,
                        city: job.City,
                        zipCode: job.Zipcode,
                        sjId: job.ShareJobId,
                        jobAddress: job.Address
                      }}
                    />
                    <div className="d-flex gap-1 align-start">
                      <MapPinIcon />
                      <div className="d-flex direction-column flex-grow-1">
                        <p className="fw-700">
                          Zip {job.Zipcode}, {job.City}
                        </p>
                      </div>
                      <Button
                        variant="outlined"
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '4px',
                          borerColor: '#BBCDC9',
                          padding: '4px'
                        }}
                        target="_blank"
                        as={Link}
                        to={navigationLink}>
                        <NavigationArrowIcon />
                      </Button>
                    </div>
                    <div className="d-flex gap-1 align-center">
                      <CurrencyCircleDollarIcon />
                      {formatPrice(job.jobEstimatedPrice)}
                    </div>
                  </div>
                );
              })
            ) : (
              <h4 className="text-secondary font-lg text-center mt-4">
                We havent found jobs for these filters
              </h4>
            )}
          </div>
        </div>
      </div>
      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} anchor="bottom">
        <Filters onClose={() => setIsOpen(false)} applyFilters={applyFilters} />
      </Drawer>
    </>
  );
};

export default JobHistoryPage;
