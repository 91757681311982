import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { IActivityLogItem } from 'types/jobs';
import './ActivityLogItem.scss';

interface ActivityLogItemProps {
  item: IActivityLogItem;
}

const formatDate = (dateString: string) => {
  const format = 'MM/dd/yyyy HH:mm';
  return DateTime.fromISO(dateString).toFormat(format);
};

const ActivityLogItem: FC<ActivityLogItemProps> = (props) => {
  const {
    item: { CreateDate, Text, Link, EntityType }
  } = props;
  return (
    <div className="ActivityLogItem">
      <p>{formatDate(CreateDate)}</p>
      <p className="text-secondary">{Text}</p>
      {EntityType == 2 && Link && <audio style={{ width: '100%' }} controls src={Link}></audio>}
    </div>
  );
};

export default ActivityLogItem;
