import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Message from './components/Message';

import { queryClient } from 'App';
import { ArrowLeftIcon, PaperPlaneIcon, VerifiedIcon } from 'assets';
import { Button, CircularProgress, Divider, PageLoader, Textarea } from 'components/ui';
import { formatToHumanDate } from 'helpers/formatToHumanDate';
import { groupMessagesByDate } from 'helpers/groupMessagesByDate';
import { chatService } from 'services/chatService';
import './ChatPage.scss';

const ChatPage = () => {
  const navigate = useNavigate();
  const [msgText, setMsgText] = useState('');
  const [searchParams] = useSearchParams();
  const listRef = useRef<HTMLDivElement | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const msgsRef = useRef<HTMLDivElement | null>(null);
  const notScrolledYet = useRef(true);

  const { sk } = useParams<{ sk: string }>();

  const { data, isLoading: isChatLoading } = useQuery({
    queryKey: ['chat'],
    queryFn: () => chatService.getChat({ sk: sk || '' }),
    refetchInterval: 5000
  });

  const { mutate, isLoading: isSendMsgLoading } = useMutation({
    mutationFn: chatService.sendMessage,
    onSuccess: (data) => {
      queryClient.setQueryData(['chat'], data);
      setMsgText('');
    }
  });

  useEffect(() => {
    // For default message text
    const jobId = searchParams.get('jobId');
    const address = searchParams.get('address');
    const jobType = searchParams.get('jobType');
    if (jobId && address && jobType) {
      setMsgText(`Job#${jobId} | ${address} | ${jobType}: \n\n`);
    }
  }, []);

  useEffect(() => {
    // For scroll things
    if (listRef.current) {
      const scrollHeight = listRef.current.scrollHeight;
      const offsetBottom = scrollHeight - listRef.current.scrollTop - listRef.current.offsetHeight;
      if (offsetBottom <= 230) {
        lastMessageRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }

    if (msgsRef.current && data?.data.chat.length && notScrolledYet.current) {
      msgsRef.current.scrollIntoView({ block: 'end', behavior: 'auto' });
      notScrolledYet.current = false;
    }
  }, [data]);

  const submit = () => {
    if (msgText.trim().length === 0) return;
    mutate({ sk: sk || '', msg: msgText.trim() });
  };

  const renderMessages = useMemo(() => {
    const groupedMsgs = groupMessagesByDate(data?.data.chat || []);

    if (!Object.values(groupedMsgs).length) {
      return <p className="text-center fw-500 text-secondary p-4">There are no messages yet</p>;
    }
    return Object.keys(groupedMsgs).map((date, i) => {
      return (
        <React.Fragment key={date}>
          {i !== 0 && <Divider />}
          <div className="MessageList-date-group">
            <div className="MessageList-date-label-container">
              <div className="MessageList-date-label">{formatToHumanDate(date)}</div>
            </div>

            {groupedMsgs[date].map((msg, i, arr) => (
              <Message
                msg={msg}
                key={msg.chatId}
                isOwn={msg.to === null}
                ref={i === arr.length - 1 ? lastMessageRef : null}
              />
            ))}
          </div>
        </React.Fragment>
      );
    });
  }, [data?.data.chat]);

  if (isChatLoading) {
    return <PageLoader />;
  }

  return (
    <div className="ChatPage">
      <div className="ChatPage-header d-flex gap-1 align-center p-3">
        <ArrowLeftIcon className="pointer" onClick={() => navigate(-1)} />
        <p className="fw-500 font-lg d-flex gap-1 align-center">
          Chat {data?.data.title}
          <VerifiedIcon />
        </p>
      </div>
      <div className="ChatPage-content" ref={listRef}>
        {isChatLoading ? (
          <CircularProgress />
        ) : (
          <div className="ChatPage-messages" ref={msgsRef}>
            {renderMessages}
          </div>
        )}
      </div>
      <div className="ChatPage-footer">
        <Textarea
          maxHeight="130px"
          value={msgText}
          onChange={(e) => setMsgText(e.target.value)}
          rows={1}
          autoResize
          ref={(node) => {
            const valLenggth = node?.value.length ?? null;
            node?.setSelectionRange(valLenggth, valLenggth);
            node && node.focus();
          }}
        />
        <Button
          variant="text"
          onClick={submit}
          disabled={isSendMsgLoading || msgText.trim().length === 0}>
          {isSendMsgLoading ? (
            <CircularProgress style={{ width: '28px', height: '28px' }} />
          ) : (
            <PaperPlaneIcon />
          )}
        </Button>
      </div>
    </div>
  );
};

export default ChatPage;
