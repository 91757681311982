import { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Button, Divider, Input, Select } from 'components/ui';
import { Filters as FiltersType } from 'services/jobsService';
import './Filters.scss';
import 'react-phone-input-2/lib/style.css';
import '../../../../styles/phone-input.scss';
import { CloseIcon } from 'assets';
import { ShareStatusesNames } from 'types/generalData';

const jobStatusesOptions = [
  { value: ShareStatusesNames.Open, title: 'Open' },
  { value: ShareStatusesNames.Sent, title: 'Sent' },
  { value: ShareStatusesNames.Approved, title: 'Approved' },
  { value: ShareStatusesNames.Claim, title: 'Claim' },
  { value: ShareStatusesNames.Closed, title: 'Closed' },
  { value: ShareStatusesNames.Canceled, title: 'Canceled' },
  { value: ShareStatusesNames.RequestToFollowup, title: 'Request to Follow Up' }
];

interface FiltersProps {
  onClose: () => void;
  applyFilters: (filters: FiltersType) => void;
}

const Filters: FC<FiltersProps> = (props) => {
  const { onClose, applyFilters } = props;
  const [params] = useSearchParams();

  const { register, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      dateFrom: params.get('DateFrom') ?? '',
      dateTo: params.get('DateTo') ?? '',
      zip: params.get('Zip') ?? '',
      phone: params.get('Phone') ?? '',
      jobId: params.get('JobId') ?? '',
      status: params.get('Status') ?? ''
    }
  });

  const submit = handleSubmit((values) => {
    const { dateFrom, dateTo, jobId, phone, zip, status } = values;
    applyFilters({
      JobId: jobId,
      DateFrom: dateFrom,
      DateTo: dateTo,
      ZipCode: zip,
      Phone: phone,
      Status: status
    });
    onClose();
  });

  const clearFilters = () => {
    reset({
      dateFrom: '',
      dateTo: '',
      jobId: '',
      phone: '',
      zip: '',
      status: ''
    });
  };

  return (
    <form className="Filters" onSubmit={submit}>
      <div className="d-flex align-center mb-2 fw-700 font-lg pt-3 pl-3 pr-3 space-between">
        <h4 className="">Filters</h4>
        <Button variant="text" type="button" style={{ padding: 0 }} onClick={onClose}>
          <CloseIcon />
        </Button>
      </div>
      <Divider />
      <div className="p-3">
        <p className="fw-700 mb-1">Date</p>
        <div className="d-flex gap-1 mb-2">
          <Input label="From date" type="date" fullWidth {...register('dateFrom')} />
          <Input label="To date" type="date" fullWidth {...register('dateTo')} />
        </div>
        <div className="d-flex gap-1 mb-2">
          <Select
            label="Job Status"
            fullWidth
            {...register('status')}
            options={jobStatusesOptions}
          />
        </div>
        <Input className=" mb-2" label="Zip Code" fullWidth {...register('zip')} />
        <p className="fw-700 mb-1">Phone</p>
        <PhoneInput
          country={'us'}
          containerClass="phone-input-container mb-2"
          inputClass="phone-input-field"
          buttonClass="phone-input-button"
          dropdownClass="phone-input-dropdown"
          onChange={(value) => {
            setValue('phone', value);
          }}
          value={watch('phone')}
        />
        <Input className="mb-4" label="Job #" fullWidth {...register('jobId')} />
        <div className="d-flex gap-3">
          <Button style={{ flexGrow: 1 }} variant="outlined" type="button" onClick={clearFilters}>
            Clear
          </Button>
          <Button style={{ flexGrow: 1 }} type="submit">
            Show Results
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Filters;
