import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Divider, PageLoader, Select, Textarea } from 'components/ui';
import { ArrowLeftIcon } from 'assets';
import { GeneralData } from 'types/generalData';
import { Job } from 'types/jobs';
import './CancelJob.scss';

interface CancelJobProps {
  submitFn: (args: { comments: string; reason: string }) => void;
  isCancelLoading: boolean;
  job?: Job;
  isJobLoading: boolean;
  cancelReasons?: GeneralData['CancelReasons'];
}

const CancelJob: FC<CancelJobProps> = (props) => {
  const { isCancelLoading, submitFn, isJobLoading, cancelReasons } = props;
  const navigate = useNavigate();
  const params = useParams<{ sjId: string }>();

  const {
    register,
    watch,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      comments: '',
      reason: 'DEFAULT'
    }
  });

  const submit = handleSubmit((values) => {
    const payload = {
      comments: values.comments,
      reason: values.reason
    };
    submitFn(payload);
  });

  const cancelRsns = useMemo(
    () =>
      cancelReasons?.map((reason) => {
        return {
          value: reason.Id,
          title: reason.Reason
        };
      }),
    [cancelReasons]
  );

  const isOptionSelected = watch('reason') !== 'DEFAULT';

  if (isJobLoading) {
    return <PageLoader />;
  }
  return (
    <div className="CancelJob">
      <div className="d-flex gap-1 align-center p-2 ">
        <ArrowLeftIcon className="pointer" onClick={() => navigate(-1)} />
        <p className="font-lg">Back</p>
      </div>
      <Divider />
      <div className="CancelJob-content">
        <p className="fw-500 mb-1 font-lg">Cancel Job # {params.sjId}</p>
        <Select
          {...register('reason', { required: true })}
          label="Reason"
          fullWidth
          className="mb-2"
          options={cancelRsns}
        />
        <Textarea {...register('comments')} label="Comments" rows={10} fullWidth />
      </div>
      <div className="CancelJob-footer">
        <Button
          fullWidth
          onClick={submit}
          disabled={!isValid || !isOptionSelected || isJobLoading || isCancelLoading}
          loading={isJobLoading || isCancelLoading}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default CancelJob;
