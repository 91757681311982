import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';

import { ArrowLeftIcon } from 'assets';
import { Avatar, CircularProgress, Divider, Input } from 'components/ui';
import { chatService } from 'services/chatService';
import './ChatListPage.scss';
import { Chat } from 'types/chat';
import { useGeneralData } from 'contexts/GeneralDataContext';
import Ripple from 'components/ui/Ripple';

const ChatListPage = () => {
  const navigate = useNavigate();
  const { generalData } = useGeneralData();

  const { data, isLoading } = useQuery({
    queryKey: ['chatList'],
    queryFn: chatService.getChatList
  });

  const getAvatarProps = (chat: Chat) => {
    if (chat.title.includes('Tech')) {
      return {
        src: `https://qlfiles.s3.us-east-1.amazonaws.com/avatars/t_${generalData?.MyData.TechId}.png`
      };
    }
    return { title: chat.title };
  };

  return (
    <div className="CloseJob">
      <div className="d-flex gap-1 align-center p-2 ">
        <ArrowLeftIcon className="pointer" onClick={() => navigate(-1)} />
        <p className="font-lg">Inbox</p>
      </div>
      <Divider />
      <div className="CloseJob-content">
        <Input placeholder="Search" fullWidth />
        <div className="ChatList-list">
          {isLoading ? (
            <div className="m-x-auto p-3 d-flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              {data?.data.chats.map((chat) => (
                <Link key={chat.sk} className="ChatList-item" to={`/chat/${chat.sk}`}>
                  <div className="avatar-wrapper">
                    <Avatar {...getAvatarProps(chat)} />
                  </div>
                  <div className="ChatList-item-info">
                    <div className="ChatList-item-info-row">
                      <p className="ChatList-item-title">{chat.title}</p>
                      <div className="ChatList-item-time">{chat.sinceRead}</div>
                    </div>
                    <div className="ChatList-item-info-row">
                      <div className="ChatList-item-last-msg">
                        <span>{chat.lastMsg}</span>
                      </div>
                      {chat.unReadCount ? (
                        <div className="ChatList-item-unread-count">{chat.unReadCount}</div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatListPage;
