import { config } from 'config';
import { LocalStorageKeys } from 'constants/localStorageKeys';

class FirebaseService {
  constructor() {}

  updateFirebaseToken = async (args: {
    fbToken: string;
    st_token: string;
    deviceName: string;
    deviceId: string;
  }) => {
    const formData = new FormData();
    const data = {
      items: [
        {
          firebasetoken: args.fbToken,
          deviceName: args.deviceName,
          deviceId: args.deviceId
        }
      ]
    };
    formData.append('data', JSON.stringify(data));
    const payload = new URLSearchParams(formData as any);

    const res = await fetch(config.apiLink + '/api/Tech/UpdateFirebaseToken/', {
      method: 'POST',
      headers: { token: args.st_token },
      body: payload
    });
    const json = await res.json();
    return json;
  };

  updateFBToken = async () => {
    const st_token = localStorage.getItem(LocalStorageKeys.st_token) || '';
    const fbToken = localStorage.getItem(LocalStorageKeys.FbToken) || '';
    const deviceName = localStorage.getItem(LocalStorageKeys.DeviceName) || '';
    const deviceId = localStorage.getItem(LocalStorageKeys.DeviceId) || '';

    if (!st_token || !fbToken || !deviceId || !deviceName) return;

    await this.updateFirebaseToken({ st_token, fbToken, deviceName, deviceId });
  };
}

export const firebaseService = new FirebaseService();
