import axios, { InternalAxiosRequestConfig } from 'axios';

import { config } from 'config';
import { LocalStorageKeys } from 'constants/localStorageKeys';

const { st_token } = LocalStorageKeys;

export const axiosInst = axios.create({
  baseURL: config.apiLink
});

axiosInst.interceptors.request.use((config: InternalAxiosRequestConfig): any => {
  const token = localStorage.getItem(st_token);
  return {
    ...config,
    headers: { ...config.headers, Token: token }
  };
});

axiosInst.interceptors.response.use(
  (config) => config,
  async (err) => {
    if (err?.response?.status == 401) {
      localStorage.removeItem(st_token);
      window.location.reload();
    }
    return Promise.reject(err);
  }
);
