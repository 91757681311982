import React, { FC, useMemo } from 'react';

import ActiveJobAditionalInfo from '../AdditionalInfo/ActiveJobAditionalInfo';
import CanceledJobAdditionalInfo from '../AdditionalInfo/CanceledJobAdditionalInfo';
import ClosedJobAdditionalInfo from '../AdditionalInfo/ClosedJobAdditionalInfo';
import FollowupJobAditionalInfo from '../AdditionalInfo/FollowupJobAditionalInfo';

import { Job } from 'types/jobs';
import { PORStatuses, ShareStatusesNames } from 'types/generalData';
import JobCard from 'components/JobCard';
import './JobOverview.scss';

interface JobOverviewProps {
  job: Job;
}

const JobOverview: FC<JobOverviewProps> = (props) => {
  const { job } = props;

  const paymentOverrideMsg = useMemo(() => {
    switch (job.paymentOverrideStatus) {
      case PORStatuses.Approwed:
        return 'Payment Override request has been Approved (:';
      case PORStatuses.OnReview:
        return ' Waiting for Tech approval for Payment Override request';
      case PORStatuses.Declined:
        return 'Payment Override request has been decliend';
      default:
        return '';
    }
  }, [job.paymentOverrideStatus]);

  const additionalInfo = useMemo(() => {
    switch (job.shareStatus) {
      case ShareStatusesNames.Closed:
      case ShareStatusesNames.Completed:
      case ShareStatusesNames.InReview:
      case ShareStatusesNames.PostReview:
        return (
          <ClosedJobAdditionalInfo
            jobInfo={{
              comments: job.Comments,
              partsCost: job.partsCost,
              totalCost: job.totalCost,
              paymentMethod: job.CustomerPayMethod,
              techShare: job.techShare,
              preComments: job.preComments
            }}
          />
        );
      case ShareStatusesNames.Canceled:
        return (
          <CanceledJobAdditionalInfo
            jobInfo={{
              cancelReason: job.CancelReason,
              comments: job.Comments,
              preComments: job.preComments
            }}
          />
        );
      case ShareStatusesNames.RequestToFollowup:
        return (
          <FollowupJobAditionalInfo
            jobInfo={{ comments: job.Comments, preComments: job.preComments }}
          />
        );
      default:
        return (
          <ActiveJobAditionalInfo
            jobInfo={{
              CustomerName: job.CustomerName,
              Latitude: job.Latitude,
              Longitude: job.Longitude,
              city: job.city,
              jobAddress: job.jobAddress,
              jobCreateDate: job.jobCreateDate,
              jobEstimatedPrice: job.jobEstimatedPrice,
              zipCode: job.zipCode,
              preComments: job.preComments
            }}
          />
        );
    }
  }, [job]);

  return (
    <>
      <div className="mb-2">
        <div className="mb-2 fw-500 text-center">{paymentOverrideMsg}</div>
        <JobCard job={job} withAddres={false} withLink={false} />
      </div>
      {additionalInfo}
    </>
  );
};

export default JobOverview;
