import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import NewJob from './components/NewJob';
import JobsListByFilter from './components/JobsListByFilter';
import JobShortOverview from './components/JobShortOverview';

import HeaderWithTitle from 'components/HeaderWithTitle';
import JobsMap from 'components/JobsMap';
import { jobsService } from 'services';
import { JobListItem } from 'types/jobs';
import { shareStatusIcons } from 'helpers/shareStatusesIcon';
import { ShareStatusesNames } from 'types/generalData';
import { Button, Drawer, PageLoader } from 'components/ui';
import './JobsMapPage.scss';

export const sortByStatuses = (jobs: JobListItem[][]) => {
  const byShareStatus = {} as Record<string | number, JobListItem[]>;
  for (const jobList of jobs) {
    const jobListShareStatus = jobList[0].ShareStatus;
    byShareStatus[jobListShareStatus] = jobList;
  }
  return byShareStatus;
};

const filterfromCancelAndClosed = (jobs: JobListItem[][]) => {
  return jobs.filter(
    (jobsArr) =>
      jobsArr[0].ShareStatus !== ShareStatusesNames.Canceled &&
      jobsArr[0].ShareStatus !== ShareStatusesNames.Closed
  );
};

const JobsMapPage = () => {
  const [jobsFilter, setJobsFilter] = useState('');
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isSingleJobDrawer, setIsSingleJobDrawer] = useState(false);
  const [currentJobId, setCurrentJobId] = useState<number | null>(null);

  const { data } = useQuery({
    queryKey: ['allJobs'],
    queryFn: jobsService.getAllJobs,
    refetchInterval: 10000
  });

  const jobsByStatus = useMemo(() => {
    return sortByStatuses(filterfromCancelAndClosed(data?.data.jobs || []));
  }, [data]);

  const filteredJobs = useMemo(() => {
    if (!jobsFilter) return Object.values(jobsByStatus).flat();
    return jobsByStatus[jobsFilter];
  }, [jobsByStatus, jobsFilter]);

  const changeFilter = (jobStatus: string) => {
    setJobsFilter(jobStatus);
    setCurrentJobId(null);
    setIsOpenDrawer(true);
  };

  const jobsTabs = useMemo(() => {
    return Object.keys(jobsByStatus).map((jobStatus) => {
      const Icon = shareStatusIcons[jobStatus as unknown as ShareStatusesNames];
      const jobsCount = jobsByStatus[jobStatus].length;

      return (
        <li
          className={`list__item ${jobsFilter === jobStatus ? 'list__item-active' : ''}`}
          key={jobStatus}
          onClick={() => changeFilter(jobStatus)}>
          <button className="list__item-btn">
            <span className="list__item-count">{jobsCount}</span>
            <Icon width="16px" height="16px" />
          </button>
        </li>
      );
    });
  }, [data, jobsFilter]);

  const newJobs = useMemo(() => {
    return filteredJobs?.filter(
      (job) =>
        job.ShareStatus === ShareStatusesNames.Sent || job.ShareStatus === ShareStatusesNames.Open
    );
  }, [jobsByStatus]);

  useEffect(() => {
    if (newJobs?.length) {
      setIsOpenDrawer(true);
    }
  }, [newJobs]);

  const drawerContent = useMemo(() => {
    if (newJobs?.length) {
      return <NewJob job={newJobs[0]} onClose={() => setIsOpenDrawer(false)} />;
    }
    return <JobsListByFilter jobs={filteredJobs} />;
  }, [filteredJobs, newJobs]);

  const singleJobDrawerContent = useMemo(() => {
    const jobToShow = filteredJobs?.find((job) => job.ShareJobId === currentJobId);
    if (jobToShow) {
      return <JobShortOverview job={jobToShow} />;
    }
    return null;
  }, [filteredJobs, currentJobId]);

  const setCurrentJob = useCallback((sjId: number) => {
    setCurrentJobId(sjId);
    setIsSingleJobDrawer(true);
  }, []);

  const closeDrawer = () => {
    setIsOpenDrawer(false);
  };

  if (!data) {
    return <PageLoader />;
  }

  return (
    <div className="JobsMapPage">
      <HeaderWithTitle jobsCount={Object.values(jobsByStatus).flat().length}></HeaderWithTitle>
      <div className="">
        <ul className="list">{jobsTabs}</ul>
      </div>
      <div className="map-wrapper">
        <JobsMap jobs={filteredJobs} setCurrentJob={setCurrentJob} />
      </div>
      {!isOpenDrawer && jobsFilter && (
        <div className="show-jobs-list">
          <Button fullWidth onClick={() => setIsOpenDrawer(true)}>
            Show selected jobs
          </Button>
        </div>
      )}
      <Drawer anchor="bottom" isOpen={isOpenDrawer} onClose={closeDrawer} overlayTransparent>
        {drawerContent}
      </Drawer>
      <Drawer
        anchor="bottom"
        isOpen={isSingleJobDrawer}
        onClose={() => setIsSingleJobDrawer(false)}
        overlayTransparent>
        {singleJobDrawerContent}
      </Drawer>
    </div>
  );
};

export default JobsMapPage;
