import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import './TimeEstimate.scss';

interface ITimeEstimate {
  title: string;
  submitFn: (args: { sjId: number; minutes: number }) => Promise<AxiosResponse>;
  onClose: () => void;
}

const list = [
  {
    value: 15,
    text: '15 Minutes'
  },
  {
    value: 30,
    text: '30 Minutes'
  },
  {
    value: 45,
    text: '45 Minutes'
  },
  {
    value: 60,
    text: '60 Minutes'
  },
  {
    value: 99,
    text: 'More then an hour'
  }
];
const TimeEstimate: FC<ITimeEstimate> = (props) => {
  const { title, submitFn, onClose } = props;
  const { sjId } = useParams<{ sjId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationFn: async (minutes: number) => {
      await submitFn({ sjId: Number(sjId || ''), minutes });
      enqueueSnackbar('Time has been estimated', { variant: 'success' });
      onClose();
    }
  });

  return (
    <div className="TimeEstimate">
      <h4 className="fw-700 mb-2 primary-color">{title}</h4>
      <ul className="main-list">
        {list.map(({ value, text }) => (
          <li className="main-list-item" key={value} onClick={() => mutate(value)}>
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TimeEstimate;
