import { LocalStorageKeys } from 'constants/localStorageKeys';

const { EnterCodeTimerId } = LocalStorageKeys;

export const formstUnit = (unit: number) => (unit < 10 ? `0${unit}` : unit);
const nSecondFromNow = (n: number) => new Date(Date.now() + n * 1000);

export const getDefaultTimerValue = () => {
  const timestampFromLocalStorage = localStorage.getItem(EnterCodeTimerId);
  if (timestampFromLocalStorage && Date.now() < new Date(timestampFromLocalStorage).getTime()) {
    return new Date(timestampFromLocalStorage);
  }

  const newTimeStamp = nSecondFromNow(60);
  localStorage.setItem(EnterCodeTimerId, newTimeStamp.toString());
  return newTimeStamp;
};

export const resetTimestamp = () => {
  const newTimeStamp = nSecondFromNow(60);
  localStorage.setItem(EnterCodeTimerId, newTimeStamp.toString());
  return newTimeStamp;
};

export const clearTimerFormLocalstorage = () => {
  localStorage.removeItem(EnterCodeTimerId);
};

export const isTimerExpired = () => {
  const timestampFromLocalStorage = localStorage.getItem(EnterCodeTimerId);
  if (!timestampFromLocalStorage) return true;

  if (timestampFromLocalStorage && Date.now() > new Date(timestampFromLocalStorage).getTime()) {
    return true;
  }

  return false;
};
