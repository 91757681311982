import axios from 'axios';

import { config } from 'config';

interface SignupData {
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
  companyNumber: string;
  address: string;
  yearsOfExpirience: string;
  driverLicense: string;
  jobTypes: string;
}

class AuthService {
  constructor() {}

  _baseUrl = 'https://auth.workswellai.com';

  _preparePayload = (items: Record<any, any>) => {
    const formData = new FormData();
    const data = {
      items: [items]
    };
    formData.append('data', JSON.stringify(data));
    return new URLSearchParams(formData as any);
  };

  async login() {}

  async signup(data: SignupData) {
    const uppercasedData = {
      FirstName: data.firstName,
      LastName: data.lastName,
      Phone: data.phone,
      CompanyName: data.companyName,
      Address: data.address,
      CompanyNumber: data.companyNumber,
      YearsOfExperience: data.yearsOfExpirience,
      DriverLicense: data.driverLicense,
      JobTypes: data.jobTypes
    };
    const payload = this._preparePayload(uppercasedData);
    return axios.post(this._baseUrl + '/api/signup/Add/', payload);
  }
  async loginVerifyCode(subTechId: string, code: string) {
    return axios.get(this._baseUrl + `/api/share/verify/${subTechId}/${code}`);
  }
  async signupVerifyCode(phone: string, code: string) {
    const payload = this._preparePayload({ Phone: phone, Code: code });
    return axios.post(this._baseUrl + `/api/signup/Verify/`, payload);
  }
  async terminateAccount() {}
  async getCodeByPhone(phone: string) {
    return axios.get(this._baseUrl + `/api/share/sendmecode2phone/${phone}`);
  }
  async getSignupGeneralData() {
    return axios.post('https://auth.workswellai.com' + `/api/signup/GetGeneralData`);
  }
}

export const authService = new AuthService();
