import { useCallback } from 'react';
type Ref<T> = React.MutableRefObject<T | null> | ((elem: T | null) => void) | null | undefined;

export const useCombinedRef = <T>(...refs: Ref<T>[]) => {
  const cbRef = useCallback((elem: T | null) => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(elem);
      } else {
        ref.current = elem;
      }
    });
  }, []);

  return cbRef;
};
