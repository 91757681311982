import axios, { AxiosInstance } from 'axios';

import { axiosInst } from '../axiosInst';

import { config } from 'config';
import { IActivityLogItem, Job, JobListResponse } from 'types/jobs';

interface CancelJobData {
  sjId: number;
  techId: number;
  LL: string;
  jobId: number;
  reasonId: string;
  comments: string;
}

interface CloseJobData {
  sjId: number | string;
  LL: string;
  totalCost: string;
  partsCost: string;
  comments: string;
  invoiceNumber: string;
  customerPayMethod: number;
}

export interface PaymentOverrideArgs {
  sjId: string;
  jobId: string | number;
  LL: string;
  totalCost: string;
  techShare: string;
  companyShare: string;
  customerPaymentType: number;
  partsCost: string;
  techComments?: string;
}

export type Filters = Partial<{
  JobId: string;
  Phone: string;
  ZipCode: string;
  DateFrom: string;
  DateTo: string;
  Status: string;
}>;

class JobsService {
  constructor(private axiosInst: AxiosInstance) {
    this.axiosInst = axiosInst;
  }

  _preparePayload = (items: Record<any, any>) => {
    const formData = new FormData();
    const data = {
      items: [items]
    };
    formData.append('data', JSON.stringify(data));
    return new URLSearchParams(formData as any);
  };

  async getAllJobs() {
    return axiosInst.get<JobListResponse>(`/api/share/getjobs/`);
  }

  getAllJobsWithFilters = async (filters: Filters = {}) => {
    const payload = this._preparePayload(filters);
    return this.axiosInst.post<JobListResponse>(`/api/share/getjobs/`, payload);
  };

  async getJob(sjId: string) {
    return axiosInst.get<Job>(`/api/share/getjob/${sjId}`);
  }
  async getJobAcivityLog(jobId: number) {
    return axiosInst.post<{ history: IActivityLogItem[] }>(`/api/share/GetJobHistory/${jobId}`);
  }
  async getJobByTech(sjId: string, token: string) {
    return axios.post<Job>(config.apiLink + `/api/Techadmin/GetJob/${sjId}`, null, {
      headers: { token }
    });
  }
  async cancelJob(cancelJobData: CancelJobData) {
    const payload = this._preparePayload(cancelJobData);
    return axiosInst.post(`/api/Share/Job/Cancel/`, payload);
  }

  async markAsSeen(sjId: number) {
    return axiosInst.get(`/api/share/isawit/${sjId}`);
  }
  async closelJob(args: CloseJobData) {
    const payload = this._preparePayload(args);
    return axiosInst.post(`/api/Share/closejob/`, payload);
  }
  async followUpJob(args: { sjId: string; comments: string }) {
    const payload = this._preparePayload(args);
    return axiosInst.post(`/api/Share/convertToFollowUp/`, payload);
  }
  async paymentOverrideRequestJob(args: PaymentOverrideArgs) {
    const payload = this._preparePayload(args);
    return axiosInst.post(`/api/share/Job/PaymentOverrideRequest/`, payload);
  }

  estimateArrivalJob = async (args: { sjId: number; minutes: number }) => {
    const payload = this._preparePayload(args);
    return this.axiosInst.post(`/api/share/Job/TimeToArrival/`, payload);
  };
  estimateCompletionJob = async (args: { sjId: number; minutes: number }) => {
    const payload = this._preparePayload(args);
    return this.axiosInst.post(`/api/share/Job/EstimateTime/`, payload);
  };
  async changeJobStatus() {}
  async getCall(args: { jobShareId: number; alternative: number }) {
    const payload = this._preparePayload(args);
    return axiosInst.post(`api/Share/Call/`, payload);
  }

  async makeDecision(sjId: number, decision: number) {
    return axiosInst.get(`api/Share/decisionWithToken/${sjId}/${decision}`);
  }
  async makeDecisionNew(args: { sjId: number; decision: number; comment: string }) {
    const payload = this._preparePayload(args);
    return axiosInst.post(`/api/Share/decisionWithToken`, payload);
  }

  async rescheduleJob(args: { sjId: string; d: string }) {
    const payload = this._preparePayload(args);
    return axiosInst.post(`/api/Share/Job/reschedule/`, payload);
  }
}

export const jobsService = new JobsService(axiosInst);
