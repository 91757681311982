import './MainMenu.scss';

import { Link } from 'react-router-dom';

import { CaretRightIcon, GearSixIcon, HistoryIcon } from 'assets';
import { Avatar, Button, Divider } from 'components/ui';
import { useAuth } from 'contexts/AuthContext';
import { useGeneralData } from 'contexts/GeneralDataContext';
import { useSidebar } from 'contexts/SidebarContext';
import { version } from 'autobuild_version';

const MainMenu = () => {
  const { closeSidebar } = useSidebar();
  const { logout } = useAuth();
  const { generalData } = useGeneralData();

  const logOut = () => {
    logout();
    closeSidebar();
  };

  return (
    <div className="Mainmenu">
      <Avatar
        className="mb-1"
        src={`https://qlfiles.s3.us-east-1.amazonaws.com/avatars/st_${generalData?.MyData?.Id}.png`}
      />
      <Link to="/profile" className="d-flex space-between align-center mb-3" onClick={closeSidebar}>
        <div className="d-flex direction-column">
          <h4 className="font-lg mb-1">{generalData?.MyData.NickName}</h4>
          <p className="text-secondary">{generalData?.MyData.Email}</p>
        </div>
        <CaretRightIcon className="rotate-270" />
      </Link>

      <ul className="main-list">
        <li className="main-list-item">
          <Link to="/jobs-history" className="d-flex gap-1" onClick={closeSidebar}>
            <HistoryIcon /> Jobs History
          </Link>
        </li>
        <li className="main-list-item">
          <Link to="/" className="d-flex gap-1" onClick={closeSidebar}>
            <GearSixIcon /> Settings
          </Link>
        </li>
      </ul>

      <div className="Mainmenu-footer">
        <Button variant="text" onClick={logOut}>
          Sign out
        </Button>
        <Divider />
        <a
          target="_blank"
          href="https://workswellai.com/Privacy_Policy.html"
          className="link d-flex p-1"
          rel="noreferrer">
          Privacy policy
        </a>
        <a
          target="_blank"
          href="https://workswellai.com/terms.html"
          className="link d-flex p-1"
          rel="noreferrer">
          Terms and Conditions
        </a>
        {version ? <p className="text-secondary font-sm p-1">Version: {version}</p> : null}
      </div>
    </div>
  );
};

export default MainMenu;
