import React, { FC } from 'react';

import JobCard from 'components/JobCard';
import { JobListItem } from 'types/jobs';
import './JobShortOverview.scss';
interface JobShortOverviewProps {
  job: JobListItem;
}
const JobShortOverview: FC<JobShortOverviewProps> = (props) => {
  const { job } = props;
  return (
    <div className="JobShortOverview">
      <JobCard
        className="mb-2"
        job={{
          ...job,
          jobId: job.JobId,
          jobType: job.TypeAndIgnitionKey,
          txt2copy: 'MOck text to copy',
          shareStatus: job.ShareStatus,
          city: job.City,
          zipCode: job.Zipcode,
          sjId: job.ShareJobId,
          jobAddress: job.Address
        }}
      />
    </div>
  );
};

export default JobShortOverview;
