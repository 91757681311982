import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import './Accordion.scss';
import { AccordionContext } from './AccordionContext';

interface IAccordionProps {
  children: React.ReactNode[];
}

const Accordion = forwardRef<HTMLDivElement, IAccordionProps>((props, ref) => {
  const [expanded, setExpanded] = useState(false);

  const [summary, ...restChildren] = React.Children.toArray(props.children);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const toogleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const contextValue = useMemo(() => {
    return { expanded, toogleExpanded };
  }, [expanded]);

  return (
    <div className="accordion" ref={ref}>
      <AccordionContext.Provider value={contextValue}>{summary}</AccordionContext.Provider>
      <CSSTransition nodeRef={bodyRef} in={expanded} timeout={250} classNames="body-expanded">
        <div className="accordion-body-container" ref={bodyRef}>
          {restChildren}
        </div>
      </CSSTransition>
    </div>
  );
});

Accordion.displayName = 'Accrodion';

export default Accordion;
