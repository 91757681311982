export const formatPrice = (price: string) => {
  const priceToNumber = parseInt(price);
  if (isNaN(priceToNumber)) {
    return price;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 1
  }).format(priceToNumber);
};
