import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import { FC, memo } from 'react';
import { useForm } from 'react-hook-form';

import { SignupData } from '../../SignupPage';

import signup from 'assets/imgs/signup.png';
import { Button, FileInput, Input } from 'components/ui';
import AddressInput from 'components/AddressInput/AddressInput';

import './SignupForm.scss';

interface SignupFormProps {
  setData: (args: Omit<SignupData, 'jobTypes'>) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
  companyNumber: string;
  address: string;
  yearsOfExpirience: string;
  driverLicense: FileList;
}

const SignupForm: FC<SignupFormProps> = (props) => {
  const { setData } = props;
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      companyName: '',
      companyNumber: '',
      address: '',
      yearsOfExpirience: '',
      driverLicense: undefined
    }
  });

  const submit = handleSubmit((data) => {
    setData(data);
  });

  return (
    <>
      <div className="Signup-form">
        <div className="Signup-form-content">
          <div className="mb-4">
            <div className="d-flex justify-center mb-4">
              <img src={signup} alt="logo" />{' '}
            </div>
            <h4 className="font-lg fw-700 text-center mb-1">Welcome to Workswell.ai!</h4>
            <p className="text-secondary text-center">First, fill in your details</p>
          </div>
          <Input label="First Name" className="mb-2" fullWidth {...register('firstName')} />
          <Input label="Last Name" className="mb-2" fullWidth {...register('lastName')} />
          <PhoneInput
            country={'us'}
            containerClass="phone-input-container mb-2"
            inputClass="phone-input-field"
            buttonClass="phone-input-button"
            dropdownClass="phone-input-dropdown"
            onChange={(value) => {
              setValue('phone', value);
            }}
          />
          <Input label="Company Name" className="mb-2" fullWidth {...register('companyName')} />
          <Input label="Company Number" className="mb-2" fullWidth {...register('companyNumber')} />
          <AddressInput setValue={(val) => setValue('address', val)} value={watch('address')} />
          <Input
            label="Years of Experience"
            className="mb-2"
            fullWidth
            {...register('yearsOfExpirience')}
          />
          <FileInput
            label="Driver License"
            placeholder="Upload Image"
            className="mb-4"
            {...register('driverLicense')}
          />
        </div>
        <div className="Signup-form-footer">
          <Button fullWidth className="mb-2" onClick={submit} disabled={!isValid}>
            Next
          </Button>
          <p className="text-secondary text-center">
            Already have an account?{' '}
            <Link to="/login" className="link">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default memo(SignupForm);
