import React from 'react';
import PhoneInput from 'react-phone-input-2';

import './ShareJob.scss';
import 'react-phone-input-2/lib/style.css';
import '../../../../styles/phone-input.scss';
import { Button } from 'components/ui';

const ShareJob = () => {
  return (
    <div className="ShareJob">
      <h4 className="fw-700 mb-1 primary-color font-lg">Share Job</h4>
      <p className="text-secondary mb-2">Please enter phone number to share with</p>
      <div className="mb-2">
        <p className="fw-700 mb-1">Phone</p>
        <PhoneInput
          country={'us'}
          containerClass="phone-input-container mb-2"
          inputClass="phone-input-field"
          buttonClass="phone-input-button"
          dropdownClass="phone-input-dropdown"
        />
      </div>
      <Button fullWidth>Share</Button>
    </div>
  );
};

export default ShareJob;
