import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Button, PageLoader } from 'components/ui';
import logo from 'assets/imgs/Workswell.png';
import { techService } from 'services/techService';
import './OpenSmsPage.scss';

const OpenSmsPage = () => {
  const { sjId, techToken } = useParams<{ sjId: string; techToken: string }>();

  const { data: generalData, isLoading: isGDLoading } = useQuery({
    queryKey: ['techGeneralData'],
    queryFn: () => techService.getGeneralData(techToken || '')
  });

  const { data, isLoading: isJobLoading } = useQuery({
    queryKey: ['job', sjId],
    queryFn: () => techService.getJob(sjId || '', techToken || '')
  });

  const jobStatusName = generalData?.data.JobStatuses.find(
    (jobStatus) => jobStatus?.Id === data?.data.job?.Status
  )?.Status;

  const jobTypeName = generalData?.data.JobTypes.find(
    (jobType) => jobType.JobTypeId === data?.data.job?.Type
  )?.Name;

  if (isJobLoading || isGDLoading) {
    return <PageLoader />;
  }

  return (
    <div className="OpenSmsPage">
      <div className="d-flex justify-center mb-4">
        <img src={logo} alt="logo" />
      </div>
      <div className="text-center">
        <p className="mb-1 font-lg">
          <span className="fw-500">Job Status: </span> {jobStatusName ?? '-'}
        </p>
        <p className="mb-1 font-lg">
          <span className="fw-500"> JobId: </span>#{data?.data.job?.JobId}{' '}
          {data?.data.job?.ExternalJobId ? `(${data?.data.job?.ExternalJobId})` : null}
        </p>
        <p className="mb-1 font-lg">
          <span className="fw-500"> Type: </span> {jobTypeName ?? '-'}
        </p>
        <p className="mb-1 font-lg">
          <span className="fw-500">Job Address: </span>
          {data?.data.job?.Type}
        </p>
        <p className="mb-1 font-lg">{data?.data.job?.Address}</p>
      </div>
      {data?.data.job?.Status === 1 && (
        <>
          <Button as={Link} to={`/close/${sjId}/${techToken}`} fullWidth>
            Close Job
          </Button>
          <Button as={Link} to={`/cancel/${sjId}/${techToken}`} variant="outlined" fullWidth>
            Cancel Job
          </Button>
        </>
      )}
    </div>
  );
};

export default OpenSmsPage;
