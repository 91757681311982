import React, { FC, useState } from 'react';

import { Button, Textarea } from 'components/ui';
import './SkipComments.scss';

interface ISkipCommentsProps {
  back: () => void;
  confirm: (comment: string) => void;
}

const SkipComments: FC<ISkipCommentsProps> = (props) => {
  const { back, confirm } = props;
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');

  const submit = () => {
    const trimmerComment = comment.trim();

    if (!trimmerComment.length) {
      setError('Type at least 5 symbols');
      return;
    }
    setError('');
    confirm(comment);
  };

  return (
    <div className="SkipComments">
      <Textarea
        label="What’s the reason for skipping?"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="mb-2"
        fullWidth
        rows={5}
        error={error}
      />
      <div className="d-flex gap-2">
        <Button variant="outlined" style={{ flexGrow: 1 }} onClick={back}>
          Back
        </Button>
        <Button style={{ flexGrow: 1 }} onClick={submit}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SkipComments;
