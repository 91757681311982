import {
  ActiveIcon,
  CancelRequestIcon,
  ClaimIcon,
  ClosedIcon,
  CompleteIcon,
  FollowUpIcon,
  InReviewIcon,
  NewIcon,
  PostReviewIcon,
  SharedPendingIcon
} from 'assets';
import { ShareStatusesNames } from 'types/generalData';

export const shareStatusIcons = {
  [ShareStatusesNames.Open]: NewIcon,
  [ShareStatusesNames.Approved]: ActiveIcon,
  [ShareStatusesNames.Sent]: SharedPendingIcon,
  [ShareStatusesNames.Canceled]: CancelRequestIcon,
  [ShareStatusesNames.Closed]: ClosedIcon, //
  [ShareStatusesNames.Claim]: ClaimIcon, //
  [ShareStatusesNames.Completed]: CompleteIcon, //
  [ShareStatusesNames.InReview]: InReviewIcon, //
  [ShareStatusesNames.PostReview]: PostReviewIcon, //
  [ShareStatusesNames.RequestToFollowup]: FollowUpIcon
};

export const shareStatusesTitles = {
  [ShareStatusesNames.Open]: 'Opened',
  [ShareStatusesNames.Approved]: 'Approved',
  [ShareStatusesNames.Sent]: 'Sent',
  [ShareStatusesNames.Canceled]: 'Canceled',
  [ShareStatusesNames.Closed]: 'Closed', //
  [ShareStatusesNames.Claim]: 'Claimed', //
  [ShareStatusesNames.Completed]: 'Completed', //
  [ShareStatusesNames.InReview]: 'In Review', //
  [ShareStatusesNames.PostReview]: 'Post review', //
  [ShareStatusesNames.RequestToFollowup]: 'Request to follow up'
};
