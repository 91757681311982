import classNames from 'classnames';
import React, { forwardRef } from 'react';

const CodeInput = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & { error?: boolean }
>((props, ref) => {
  const { error, ...restProps } = props;
  const codeFieldclasses = classNames({
    'input-field': true,
    'code-field': true,
    error: error
  });
  return <input {...restProps} ref={ref} type="number" className={codeFieldclasses} />;
});

CodeInput.displayName = 'CodeInput';

export default CodeInput;
