import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  BellRingingIcon,
  CommentsIcon,
  MapPinIcon,
  MoneyIcon,
  NavigationArrowIcon,
  UserIcon
} from 'assets';
import { Button } from 'components/ui';
import { createNavLink } from 'helpers/createNavigationLink';
import { formatPrice } from 'helpers/formatPrice';
import './ActiveJobAditionalInfo.scss';

interface ActiveJobAditionalInfoProps {
  jobInfo: {
    zipCode: string;
    city: string;
    jobAddress: string;
    CustomerName: string;
    jobEstimatedPrice: string;
    jobCreateDate: string;
    Latitude: number;
    Longitude: number;
    preComments: string;
  };
}

const ActiveJobAditionalInfo: FC<ActiveJobAditionalInfoProps> = (props) => {
  const { jobInfo } = props;
  const navigationLink = createNavLink({ lat: jobInfo.Latitude, lng: jobInfo.Longitude });

  return (
    <>
      <div className="d-flex gap-1 align-start mb-2">
        <MapPinIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">
            Zip {jobInfo.zipCode}, {jobInfo.city}
          </p>
          <p className="text-secondary">{jobInfo.jobAddress}</p>
        </div>
        <Button
          variant="outlined"
          style={{
            width: '32px',
            height: '32px',
            borderRadius: '4px',
            borerColor: '#BBCDC9',
            padding: '4px'
          }}
          target="_blank"
          as={Link}
          to={navigationLink}>
          <NavigationArrowIcon />
        </Button>
      </div>
      <div className="d-flex gap-1 align-start mb-2">
        <UserIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Customer Details</p>
          <p>{jobInfo.CustomerName}</p>
        </div>
      </div>
      <div className="d-flex gap-1 align-start mb-2">
        <MoneyIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Estimated Price</p>
          <p>{formatPrice(jobInfo.jobEstimatedPrice)}</p>
        </div>
      </div>
      <div className="d-flex gap-1 align-start mb-2">
        <BellRingingIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Created</p>
          <p>{new Date(jobInfo.jobCreateDate).toLocaleString()}</p>
        </div>
      </div>
      {jobInfo.preComments && (
        <div className="d-flex gap-1 align-start mb-2">
          <CommentsIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Pre Comments</p>
            <p>{jobInfo.preComments}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ActiveJobAditionalInfo;
