import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import App from './App';

import { emitCustomStorageEvent } from 'utils/emitCustomStorageEvent';

interface IFlutterData {
  fbToken: string;
  deviceName: string;
  deviceId: string;
}

window.saveDataFromFlutter = function (data: IFlutterData) {
  const { deviceId, deviceName, fbToken } = data;
  if (!deviceId ?? !deviceName ?? !fbToken) return false;

  for (const key in data) {
    localStorage.setItem(key, JSON.stringify(data[key as keyof IFlutterData]));
  }

  emitCustomStorageEvent();
  return true;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
