import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ActivityLog from './components/ActivityLog';
import JobOverview from './components/JobOverview';
import MoreActions from './components/MoreActions';
import Reschedule from './components/Reschedule';
import ShareJob from './components/ShareJob';
import TimeEstimate from './components/TimeEstimate';

import { ShareStatusesNames } from 'types/generalData';
import { jobsService } from 'services';
import { Button, Drawer, PageLoader } from 'components/ui';
import { ArrowLeftIcon, DotsIcon } from 'assets';
import { queryClient } from 'App';
import './JobDetailsPage.scss';

export type DrawerContent =
  | ''
  | 'est-job-complition'
  | 'est-job-arrival'
  | 'share-job'
  | 'reschedule';

const JobDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams<{ sjId: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [drawerContent, setDrawerContent] = useState<DrawerContent>('');

  const { data } = useQuery({
    queryKey: ['job', params.sjId],
    queryFn: () => jobsService.getJob(params.sjId || '')
  });

  const { mutate, isLoading: isLoadingDecision } = useMutation({
    mutationFn: (decision: number) => jobsService.makeDecision(Number(params.sjId || ''), decision),
    onSuccess: () => {
      queryClient.refetchQueries(['job', params.sjId]);
    }
  });

  const setContent = (contentType: DrawerContent) => {
    setDrawerContent(contentType);
  };

  const closeDraver = () => {
    setIsOpen(false);
    setDrawerContent('');
  };

  const content = useMemo(() => {
    switch (drawerContent) {
      case 'est-job-arrival':
        return (
          <TimeEstimate
            title="Estimate time to arrival"
            submitFn={jobsService.estimateArrivalJob}
            onClose={closeDraver}
          />
        );
      case 'est-job-complition':
        return (
          <TimeEstimate
            title="Estimate job completion"
            submitFn={jobsService.estimateCompletionJob}
            onClose={closeDraver}
          />
        );
      case 'share-job':
        return <ShareJob />;
      case 'reschedule':
        return <Reschedule timeOffset={data?.data.TimeRawOffset} onClose={closeDraver} />;
      default:
        return (
          <MoreActions
            paymentOverrideStatus={data?.data.paymentOverrideStatus}
            setContent={setContent}
          />
        );
    }
  }, [drawerContent, data]);

  const handleClose = () => {
    setIsOpen(false);
    setDrawerContent('');
  };

  const ableToCancelJob =
    data?.data.shareStatus !== ShareStatusesNames.Sent &&
    data?.data.shareStatus !== ShareStatusesNames.Open &&
    data?.data.shareStatus !== ShareStatusesNames.Canceled &&
    data?.data.shareStatus !== ShareStatusesNames.RequestToFollowup &&
    data?.data.shareStatus !== ShareStatusesNames.Closed &&
    data?.data.shareStatus !== ShareStatusesNames.Completed &&
    data?.data.shareStatus !== ShareStatusesNames.InReview &&
    data?.data.shareStatus !== ShareStatusesNames.PostReview;

  const jobDetailsFooter = useMemo(() => {
    switch (data?.data.shareStatus) {
      case ShareStatusesNames.Sent:
      case ShareStatusesNames.Open:
        return (
          <>
            <Button style={{ flexGrow: 1 }} onClick={() => mutate(3)} disabled={isLoadingDecision}>
              Approve
            </Button>
            <Button
              variant="outlined"
              style={{ flexGrow: 1 }}
              disabled={isLoadingDecision}
              onClick={() => mutate(4)}>
              Skip
            </Button>
          </>
        );

      case ShareStatusesNames.Canceled:
        return null;
      case ShareStatusesNames.RequestToFollowup:
        return null;
      case ShareStatusesNames.Closed:
      case ShareStatusesNames.Completed:
      case ShareStatusesNames.InReview:
      case ShareStatusesNames.PostReview:
        return null;
      default:
        return (
          <>
            <Button as={Link} to={`/close/${data?.data.sjId}`}>
              Close Job
            </Button>
            {/* <Button variant="outlined" as={Link} to={`/followup/${data?.data.sjId}`}>
              Follow Up
            </Button> */}
            <Button
              variant="outlined"
              style={{ width: '48px', height: '48px' }}
              onClick={() => setIsOpen(true)}>
              <DotsIcon />
            </Button>
          </>
        );
    }
  }, [data?.data.shareStatus]);

  if (!data) {
    return <PageLoader />;
  }

  return (
    <div className="JobDetiailsPage">
      <div className="d-flex gap-1 align-center pt-2 pr-2 pl-2">
        <ArrowLeftIcon className="pointer" onClick={() => navigate('/jobs-map')} />
        <p className="fw-700 font-lg"> Job # {data.data.jobId}</p>
        {ableToCancelJob && (
          <Button
            color="error"
            variant="text"
            style={{ marginLeft: 'auto' }}
            as={Link}
            to={`/cancel/${data.data.sjId}`}>
            Cancel Job
          </Button>
        )}
      </div>
      <div className="tabs">
        <div className={`tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
          Overview
        </div>
        <div className={`tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
          Activity Log
        </div>
      </div>
      <div className="JobDetiailsPage-content">
        {activeTab === 0 && <JobOverview job={data?.data} />}
        {activeTab === 1 && <ActivityLog jobId={data.data.jobId} />}
      </div>
      <div className="JobDetiailsPage-footer">
        {data.data.paymentOverrideStatus !== 0 && jobDetailsFooter}
      </div>
      <Drawer anchor="bottom" isOpen={isOpen} onClose={handleClose}>
        {content}
      </Drawer>
    </div>
  );
};

export default JobDetailsPage;
