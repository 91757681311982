import { createContext, useContext, useEffect, useState } from 'react';

import LocationModal from 'components/LocationModal';
import Modal from 'components/ui/Modal';
import { LocalStorageKeys } from 'constants/localStorageKeys';

interface LocationServiceContext {
  currentLocation: { lat: number; lng: number };
}

const LocationServiceContext = createContext<LocationServiceContext | null>(null);
const DEFAULT_LAT_LNG = { lat: 40.7050362, lng: -73.9783077 };
const { IsGeoModalShown } = LocalStorageKeys;

export const LocationServiceProvider = (props: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [location, setLocation] = useState(DEFAULT_LAT_LNG);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setLocation({ lat, lng });
      },
      () => {
        const isShownEarlier = localStorage.getItem(IsGeoModalShown);
        if (isShownEarlier !== 'true') {
          setIsOpen(true);
          localStorage.setItem(IsGeoModalShown, String(true));
        }
      }
    );
  }, []);

  const value = { currentLocation: location };

  return (
    <LocationServiceContext.Provider value={value}>
      {props.children}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <LocationModal onClose={() => setIsOpen(false)} />
      </Modal>
    </LocationServiceContext.Provider>
  );
};

export const useLocationService = () => {
  const context = useContext(LocationServiceContext);

  if (context === null) {
    throw Error('useLocationService must be used inside LocationServiceProvider');
  }

  return context;
};
