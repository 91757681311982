import React from 'react';

import './PageLoader.scss';
import CircularProgress from '../CircularProgress';

import logo from 'assets/imgs/Workswell.png';

const PageLoader = () => {
  return (
    <div className="PageLoader">
      <img src={logo} alt="Logo" />
      <CircularProgress />
    </div>
  );
};

export default PageLoader;
