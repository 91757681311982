import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { FC } from 'react';

import { Input } from 'components/ui';
import { config } from 'config';
import './AddressInput.scss';

interface AddressInputProps {
  setValue: (val: string) => void;
  value: string;
}

const AddressInput: FC<AddressInputProps> = (props: any) => {
  const { setValue, value } = props;
  const { placePredictions, getPlacePredictions } = usePlacesAutocompleteService({
    apiKey: config.googleMapKey,
    debounce: 150,
    language: 'ru'
  });

  const onClick = (val: any) => {
    console.log(val);
    setValue('address', val.description);
    getPlacePredictions({ input: '' });
  };

  return (
    <div className="select-container">
      <Input
        label={'Address'}
        value={value}
        fullWidth
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
          setValue('address', evt.target.value);
        }}
      />
      {placePredictions.length ? (
        <ul className="select-dropdown">
          {placePredictions.map((item: any) => (
            <li
              className="select-dropdown-item"
              key={item.place_id}
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => onClick(item)}>
              {item.description}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default AddressInput;
