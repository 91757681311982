import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';

import { AppRouter } from 'router';
import { AuthProvider } from 'contexts/AuthContext';
import { firebaseService } from 'services/firebaseService';
import { useWindowEvent } from 'hooks/useWindowEvent';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  useWindowEvent('customLocalStorage', firebaseService.updateFBToken);

  return (
    <div className="App">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <AuthProvider>
              <AppRouter />
            </AuthProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
