import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

import { Button } from 'components/ui';
import { jobsService } from 'services';
import './CallBack.scss';

interface CallBackProps {
  onClose: () => void;
  sjId: number;
}

const CallBack: FC<CallBackProps> = (props) => {
  const { onClose, sjId } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationFn: async () => {
      try {
        await jobsService.getCall({ jobShareId: sjId, alternative: 0 });
        enqueueSnackbar('Wait for a call please', {
          variant: 'success'
        });
        onClose();
      } catch (error) {
        console.log(error);
      }
    }
  });

  return (
    <div className="CallBack">
      <p className="fw-500 font-lg mb-2 text-center">Are you sure you want to get a call?</p>
      <div className="d-flex gap-2 w-100">
        <Button variant="outlined" style={{ flexGrow: 1 }} onClick={onClose}>
          Cancel
        </Button>
        <Button style={{ flexGrow: 1 }} onClick={mutate}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default CallBack;
