import React, { FC, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import SkipComments from '../SkipComments/SkipComments';

import { useGeneralData } from 'contexts/GeneralDataContext';
import { jobsService } from 'services';
import { queryClient } from 'App';
import './SkipJobReasons.scss';

interface ISkipJobReasonsProps {
  sjId: number;
  onClose: () => void;
}

const SkipJobReasons: FC<ISkipJobReasonsProps> = (props) => {
  const { sjId, onClose } = props;
  const { generalData } = useGeneralData();
  const { enqueueSnackbar } = useSnackbar();
  const [contentType, setContentType] = useState<'list' | 'comment'>('list');

  const { mutate, isLoading } = useMutation({
    mutationFn: async (args: { comments: string }) => {
      try {
        const res = await jobsService.makeDecisionNew({
          sjId,
          decision: 4,
          comment: args.comments
        });

        if (res.data.success !== 'true') {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          return;
        }
        enqueueSnackbar('Job has been skiped', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries(['allJobs']);
      onClose();
    }
  });

  const skip = (reason: string) => {
    if (reason === 'other') {
      setContentType('comment');
      return;
    }
    makeDecision(reason);
  };

  const makeDecision = (reason: string) => {
    mutate({ comments: reason });
  };

  const content = useMemo(() => {
    switch (contentType) {
      case 'comment':
        return <SkipComments back={() => setContentType('list')} confirm={makeDecision} />;
      case 'list':
      default:
        return (
          <ul className="main-list">
            {generalData?.SkipReasons.map(({ Id, Reason }) => (
              <li className="main-list-item" key={Id} onClick={() => skip(Reason)}>
                {Reason}
              </li>
            ))}
            <li className="main-list-item" onClick={() => skip('other')}>
              Other
            </li>
          </ul>
        );
    }
  }, [contentType]);

  return (
    <div className="SkipJobReasons">
      <h4 className="fw-700 mb-2 primary-color">You want to skip this job</h4>
      {content}
    </div>
  );
};

export default SkipJobReasons;
