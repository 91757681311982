import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import CallBack from './components/CallBack';

import {
  ArrowRightIcon,
  ChatCircleDotsContainedIcon,
  CopyIcon,
  MapPinIcon,
  NavigationArrowIcon,
  PhoneIcon
} from 'assets';
import JobStatus from 'components/JobStatus';
import { Button } from 'components/ui';
import Modal from 'components/ui/Modal';
import { createNavLink } from 'helpers/createNavigationLink';
import { ShareStatusesNames } from 'types/generalData';
import './JobCard.scss';

interface JobCardProps {
  job: {
    txt2copy: string;
    shareStatus: number;
    Latitude: number;
    Longitude: number;
    jobId: number;
    ExternalJobId: number | null;
    jobAddress: string;
    jobType: string;
    zipCode: string;
    city: string;
    preComments?: string | null;
    Comments?: string | null;
    sjId: number;
  };
  className?: string;
  withAddres?: boolean;
  withLink?: boolean;
  titleSize?: 'sm' | 'md' | 'lg';
}

const JobCard: FC<JobCardProps> = (props) => {
  const { job, className, withAddres = true, withLink = true, titleSize = 'lg' } = props;
  const [isOpen, setIsOpen] = useState(false);
  const copyToClipBoard = () => {
    window.navigator.clipboard.writeText(job.txt2copy);
  };

  const isCallAllowed =
    job.shareStatus === ShareStatusesNames.Approved || job.shareStatus === ShareStatusesNames.Claim;

  const navLink = createNavLink({ lat: job.Latitude, lng: job.Longitude });

  const isNeedToShowPreComments =
    job.shareStatus === ShareStatusesNames.Open || job.shareStatus === ShareStatusesNames.Sent;

  return (
    <>
      <div className={`card ${className}`}>
        <JobStatus status={job.shareStatus} />
        <div className="d-flex space-between align-center mb-1">
          <Button variant="outlined" className="job-id" onClick={copyToClipBoard} role="button">
            #{job.jobId} {job.ExternalJobId && `(${job.ExternalJobId})`} <CopyIcon />
          </Button>
          <div className="d-flex align-center gap-05">
            <Button
              variant="outlined"
              as={Link}
              to={`/chat?jobId=${job.jobId}&address=${job.jobAddress}&jobType=${job.jobType}`}
              style={{
                width: '32px',
                height: '32px',
                borderRadius: '4px',
                borerColor: '#BBCDC9',
                padding: '4px'
              }}>
              <ChatCircleDotsContainedIcon width="24px" height="24px" />
            </Button>
            {isCallAllowed && (
              <Button
                onClick={() => setIsOpen(true)}
                variant="outlined"
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '4px',
                  borderColor: '#BBCDC9',
                  padding: '4px'
                }}>
                <PhoneIcon />
              </Button>
            )}
          </div>
        </div>
        <div className="mb-1">
          <p className={`font-${titleSize} text-secondary`}>{job.jobType}</p>
        </div>
        {withAddres && (
          <>
            {' '}
            <div className="d-flex gap-1 align-center mb-1">
              <MapPinIcon />
              <div className="d-flex direction-column flex-grow-1">
                <p className="fw-700">
                  Zip {job.zipCode}, {job.city}
                </p>
              </div>
              <Button
                variant="outlined"
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '4px',
                  borderColor: '#BBCDC9',
                  padding: '4px'
                }}
                as={Link}
                to={navLink}>
                <NavigationArrowIcon />
              </Button>
            </div>
            {isNeedToShowPreComments && job.preComments && (
              <div className="mb-1">
                <p>
                  <b>Pre Comments:</b> {job.preComments}
                </p>
              </div>
            )}
            {job.Comments && (
              <div className="mb-1">
                <p>
                  <b>Comments:</b> {job.Comments}
                </p>
              </div>
            )}
          </>
        )}
        {withLink && (
          <Link
            to={`/job-details/${job.sjId}`}
            className="d-inline-flex  align-center gap-1 fw-500 font-lg">
            View job <ArrowRightIcon />
          </Link>
        )}
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <CallBack sjId={job.sjId} onClose={() => setIsOpen(false)} />
      </Modal>
    </>
  );
};

export default JobCard;
