import './LoginPage.scss';
import PhoneInput from 'react-phone-input-2';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { Button } from 'components/ui';
import logo from 'assets/imgs/Workswell.png';
import { authService } from 'services';
import 'react-phone-input-2/lib/style.css';
import '../../styles/phone-input.scss';
import { resetTimestamp } from 'helpers/enterCodePage';

const LoginPage = () => {
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation({
    mutationKey: ['getCodeByPhone'],
    mutationFn: async (phone: string) => {
      try {
        const { data } = await authService.getCodeByPhone(phone);
        if (data.success === 'true') {
          navigate(`/entercode?subTechId=${data.subTechId}&phone=${phone}`, { replace: true });
          resetTimestamp();
        }
      } catch (error) {
        console.log(error);
      }
    }
  });

  const submit = () => {
    mutate(phone);
  };

  return (
    <div className="LoginPage">
      <div className="mb-4">
        <div className="d-flex justify-center mb-4">
          <img src={logo} alt="logo" />
        </div>
        <h4 className="font-lg fw-700 text-center mb-1">Enter your phone number</h4>
        <p className="text-secondary text-center">We’ll send you a message with code</p>
      </div>
      <PhoneInput
        value={phone}
        onChange={setPhone}
        country={'us'}
        containerClass="phone-input-container mb-2"
        inputClass="phone-input-field"
        buttonClass="phone-input-button"
        dropdownClass="phone-input-dropdown"
      />
      <Button fullWidth className="mb-2" onClick={submit} disabled={isLoading} loading={isLoading}>
        Send code
      </Button>
      <p className="text-secondary text-center">
        Dont have an account?{' '}
        <Link to="/signup" className="link">
          Sign up
        </Link>
      </p>
    </div>
  );
};

export default LoginPage;
