import classNames from 'classnames';
import { FC, ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Portal from '../Portal';

import { useIsFirstRender } from 'hooks/isFirstRender';
import './Modal.scss';
interface IModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: ReactNode | ReactNode[];
}
const Modal: FC<IModalProps> = ({ isOpen, onClose, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isfirstRender = useIsFirstRender();

  const defaultActiveClass = classNames({
    'Modal-enter-active': isOpen && isfirstRender
  });

  return (
    <Portal>
      <CSSTransition in={isOpen} nodeRef={ref} timeout={300} classNames="Modal" unmountOnExit>
        <div className={`Modal ${defaultActiveClass}`} ref={ref}>
          <div className="Modal-overlay" onClick={onClose}>
            <div className="Modal-content" onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default Modal;
