import React, { forwardRef } from 'react';
import { DateTime } from 'luxon';

import { IMessage } from 'types/chat';
import './Message.scss';
interface MessageProps {
  msg: IMessage;
  isOwn: boolean;
}
const Message = forwardRef<HTMLDivElement, MessageProps>((props, ref) => {
  const { msg } = props;

  const transformDate = DateTime.fromISO(msg.SendDate).toFormat('hh:mm a');

  return (
    <div className="Message d-flex gap-2 align-start" ref={ref}>
      <div className="Message-avatar">a</div>
      <div>
        <p className="mb-1">
          <span className="fw-700 mr-1">{msg.from === null ? 'You' : msg.from}</span>
          <span className="text-secondary">{transformDate}</span>
        </p>
        <p className="text-secondary Message-text">{msg.msg}</p>
      </div>
    </div>
  );
});

Message.displayName = 'Message';

export default Message;
