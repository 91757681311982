import classNames from 'classnames';
import React, { forwardRef } from 'react';
import './Input.scss';

export type InputProp = {
  label?: string;
  id?: string;
  error?: string | undefined;
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
} & JSX.IntrinsicElements['input'];

const Input = forwardRef<HTMLInputElement, InputProp>((props, ref) => {
  const { id, label, fullWidth = false, error, className, endAdornment, ...restProps } = props;

  const inputClass = classNames({
    'input-container': true,
    'input-error': !!error,
    'input-fullWidth': fullWidth
  });

  return (
    <div className={`${inputClass} ${className}`}>
      <label className="input-label" htmlFor={id}>
        {label}
      </label>
      <div className="input-wrapper">
        <input id={id} {...restProps} ref={ref} className="input-field" />
        <div className="input-endAdornment">{endAdornment}</div>
      </div>
      <p className="input-error-message">{error}</p>
    </div>
  );
});

Input.displayName = 'Input';

export default Input;

//For password
// <div className="input__password__wrapper">
//   <input
//     {...restProps}
//     id={id}
//     className={inputClass}
//     type={showPassword ? 'text' : 'password'}
//     placeholder={placeholder}
//     ref={ref}
//   />
//   <p
//     className="input__password__show"
//     color={showPassword ? '#3751ff' : '#9fa2b4'}
//     onClick={() => setShowPassword((prev) => !prev)}
//   />
// </div>;
