import './EnterCodePage.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { useMutation } from '@tanstack/react-query';

import logo from 'assets/imgs/Workswell.png';
import { Button, OTPInput } from 'components/ui';
import {
  clearTimerFormLocalstorage,
  formstUnit,
  getDefaultTimerValue,
  resetTimestamp
} from 'helpers/enterCodePage';
import { useAuth } from 'contexts/AuthContext';
import { authService } from 'services';

const EnterCodePage = () => {
  const [otp, setOtp] = useState('');
  const [params] = useSearchParams();

  const { login, isLoginLoading } = useAuth();

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: getDefaultTimerValue(),
    onExpire: clearTimerFormLocalstorage
  });

  const { mutate: resend } = useMutation({
    mutationKey: ['getCodeByPhone'],
    mutationFn: async () => {
      try {
        const phone = params.get('phone') || '';
        await authService.getCodeByPhone(phone);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { mutate: veifySignup, isLoading: isLoadingSignup } = useMutation({
    mutationKey: ['signupVerifyCode'],
    mutationFn: async (otp: string) => {
      try {
        const phone = params.get('phone') || '';
        await authService.signupVerifyCode(phone, otp);
        clearTimerFormLocalstorage();
      } catch (error) {
        console.log(error);
      }
    }
  });

  const resendOTP = () => {
    if (isRunning) return;
    resend();
    const newTime = resetTimestamp();
    restart(newTime);
  };

  const submit = async () => {
    const subTechId = params.get('subTechId') || '';
    login(subTechId, otp);
    clearTimerFormLocalstorage();
  };

  const submitSignup = () => {
    veifySignup(otp);
  };

  useEffect(() => {
    if (otp.length === 4) {
      const isSignup = params.get('signup');
      if (isSignup) {
        submitSignup();
        return;
      }
      submit();
    }
  }, [otp]);

  const onChangeOTP = useCallback((val: string) => setOtp(val), []);

  return (
    <>
      <div className="EnterCodePage">
        <div className="mb-4">
          <div className="d-flex justify-center mb-4">
            <img src={logo} alt="logo" />
          </div>
          <h4 className="font-lg fw-700 text-center mb-1">Verify your phone number</h4>
          <p className="text-secondary text-center">We have sent you a code</p>
        </div>
        <p className="text-center fw-700 mb-2">Enter code</p>
        <OTPInput classNames="mb-2" onChange={onChangeOTP} value={otp} />
        {isRunning && (
          <p className="text-center fw-700 mb-2">
            <span>{formstUnit(minutes)}</span>:<span>{formstUnit(seconds)}</span>
          </p>
        )}
        <Button
          variant="contained"
          fullWidth
          className="mb-2"
          disabled={otp.length < 4 || isLoginLoading || isLoadingSignup}
          onClick={submit}
          loading={isLoginLoading || isLoadingSignup}>
          Verify
        </Button>
        {params.get('signup') ? null : (
          <p className="text-secondary text-center">
            Didn’t received a code yet?{' '}
            <Link
              to="/signup"
              className="link"
              onClick={(e) => {
                e.preventDefault();
                resendOTP();
              }}>
              Resend OTP
            </Link>
          </p>
        )}
      </div>
    </>
  );
};

export default EnterCodePage;
