import { FC } from 'react';

import JobCard from 'components/JobCard';
import { shareStatusesTitles } from 'helpers/shareStatusesIcon';
import { JobListItem } from 'types/jobs';
import './JobsListByFilter.scss';

interface JobsListByFilterProps {
  jobs: JobListItem[];
}

const JobsListByFilter: FC<JobsListByFilterProps> = (props) => {
  const { jobs } = props;

  return (
    <div className="JobsListByFilter">
      <h4 className="font-lg text-center fw-500 mb-2">
        {shareStatusesTitles[jobs?.[0]?.ShareStatus]} Jobs
      </h4>
      {jobs?.map((job, i, arr) => {
        return (
          <JobCard
            key={job.ShareJobId}
            className={i === arr.length - 1 ? '' : 'mb-2'}
            job={{
              ...job,
              jobId: job.JobId,
              jobType: job.TypeAndIgnitionKey,
              txt2copy: 'MOck text to copy',
              shareStatus: job.ShareStatus,
              city: job.City,
              zipCode: job.Zipcode,
              sjId: job.ShareJobId,
              jobAddress: job.Address,
              ExternalJobId: job.ExternalJobId
            }}
          />
        );
      })}
    </div>
  );
};

export default JobsListByFilter;
