import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import CloseJob from 'components/CloseJob';
import { jobsService } from 'services';
import { imagesService } from 'services/imagesService';
import { useLocationService } from 'contexts/LocationContext';
import { CloseJobData } from 'types/jobs';

const CloseJobSubtechPage = () => {
  const navigate = useNavigate();

  const { currentLocation } = useLocationService();

  const { sjId } = useParams<{ sjId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useQuery({
    queryKey: ['job', sjId],
    queryFn: () => jobsService.getJob(sjId || ''),
    keepPreviousData: true
  });

  const { mutate, isLoading: isCloseLoading } = useMutation({
    mutationFn: async (values: CloseJobData) => {
      try {
        const { partsPhoto, ...rest } = values;
        if (partsPhoto) {
          await imagesService.uploadParts({ img: partsPhoto, sjId: sjId || '' });
        }
        const resp = await jobsService.closelJob({
          ...rest,
          sjId: sjId || '',
          LL: `${currentLocation.lat},${currentLocation.lng}`
        });
        if (resp.data.success === 'true') {
          enqueueSnackbar('Job has been closed', { variant: 'success' });
          navigate(`/job-details/${sjId}`);
        } else {
          enqueueSnackbar(resp.data.msg, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  return (
    <CloseJob
      submitFn={mutate}
      isCloseLoading={isCloseLoading}
      job={data?.data}
      id={data?.data.sjId}
      isJobLoading={isLoading}
    />
  );
};

export default CloseJobSubtechPage;
