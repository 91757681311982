import './LocationModal.scss';
import React from 'react';

import Button from '../ui/Button';

import { LocationModalIcon } from 'assets';

const LocationModal = (props: { onClose: () => void }) => {
  return (
    <div className="LocationModal">
      <div className="logo">
        <LocationModalIcon />
      </div>
      <div className="modal-text mb-2">
        <p className="modal-text__item">
          In order to find the best jobs for you, our AI system should be able to determine your
          location. You’ll get the best, closest and mosT relevant content from Workswell AI. Of
          course, you can decline, but then our AI system will not work properly. Location will we
          always in use.
        </p>
      </div>
      <Button className="modal-buttons__item" fullWidth onClick={props.onClose}>
        Got it
      </Button>
    </div>
  );
};

export default LocationModal;
