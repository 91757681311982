import axios from 'axios';

import { axiosInst } from 'axiosInst';
import { config } from 'config';

interface UploadPArtsArgs {
  sjId: number | string;
  img: File;
}

class ImagesService {
  constructor() {}
  _baseUrl = config.apiLink;

  async uploadParts(args: UploadPArtsArgs) {
    const { img, sjId } = args;
    const formData = new FormData();

    formData.append('sjId', String(sjId));
    formData.append('uploadType', 'parts');
    formData.append('parts', img);

    return axiosInst.post(`/api/share/Job/UploadImageWithToken/`, formData);
  }

  async uploadPartsTech(args: UploadPArtsArgs & { token: string }) {
    const { img, sjId, token } = args;
    const formData = new FormData();

    formData.append('sjId', String(sjId));
    formData.append('uploadType', 'parts');
    formData.append('parts', img);

    return axios.post(config.apiLink + `/api/share/Job/UploadImageWithToken/`, formData, {
      headers: { token }
    });
  }

  async uploadAvatar(img: File) {
    const formData = new FormData();
    formData.append('uploadType', 'parts');
    formData.append('parts', img);
    return axiosInst.post(`/api/techcontact/UploadAvatar/`, formData);
  }

  async uploadDriverLicense(file: File) {
    const formData = new FormData();
    formData.append('parts', file);
    return axios.post(this._baseUrl + `/api/signup/UploadDriverLicense`, formData);
  }
}

export const imagesService = new ImagesService();
