import React, { FC } from 'react';

import { CommentsIcon, UserIcon } from 'assets';
import { useGeneralData } from 'contexts/GeneralDataContext';
import './CanceledJobAdditionalInfo.scss';
interface CanceledJobAdditionalInfo {
  jobInfo: {
    cancelReason: number | null;
    comments: string | null;
    preComments: string;
  };
}
const CanceledJobAdditionalInfo: FC<CanceledJobAdditionalInfo> = (props) => {
  const { jobInfo } = props;
  const { generalData } = useGeneralData();
  const reason = generalData?.CancelReasons.find((reason) => reason.Id === jobInfo.cancelReason);
  return (
    <>
      <div className="d-flex gap-1 align-start mb-2">
        <UserIcon />
        <div className="d-flex direction-column flex-grow-1">
          <p className="fw-700">Cancel reason</p>
          <p>{reason?.Reason || '-'}</p>
        </div>
      </div>
      {jobInfo.comments && (
        <div className="d-flex gap-1 align-start mb-2">
          <UserIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Comments</p>
            <p>{jobInfo.comments}</p>
          </div>
        </div>
      )}
      {jobInfo.preComments && (
        <div className="d-flex gap-1 align-start mb-2">
          <CommentsIcon />
          <div className="d-flex direction-column flex-grow-1">
            <p className="fw-700">Pre Comments</p>
            <p>{jobInfo.preComments}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CanceledJobAdditionalInfo;
