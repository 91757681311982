import PhoneInput from 'react-phone-input-2';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import DeleteModal from './components/DeleteModal';

import { Avatar, Button, Input, PageLoader, Switcher, Textarea } from 'components/ui';
import { ArrowLeftIcon, PencilIcon } from 'assets';
import { useGeneralData } from 'contexts/GeneralDataContext';
import { userService } from 'services/userService';
import { imagesService } from 'services/imagesService';
import { randomString } from 'helpers/randomString';
import Modal from 'components/ui/Modal';

import './ProfilePage.scss';
import 'react-phone-input-2/lib/style.css';
import '../../styles/phone-input.scss';

interface UpdateUserDataPayload {
  avatar: FileList | undefined;
  email: string;
  phone: string;
  nickname: string;
  comments: string;
  reminderCalls: boolean;
}

interface IFormValues {
  avatar: FileList | undefined;
  email: string;
  phone: string;
  nickname: string;
  comments: string;
  reminderCalls: boolean;
}

const ProfilePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { generalData, isGeneralDataLoading } = useGeneralData();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (values: UpdateUserDataPayload) => {
      const { avatar, comments, email, nickname, phone, reminderCalls } = values;
      if (avatar && avatar.length) {
        await imagesService.uploadAvatar(avatar[0]);
      }
      const payload = {
        TechContactId: generalData?.MyData.Id || '',
        Email: email,
        Phone: phone,
        Name: nickname,
        Comments: comments,
        ReminderCalls: reminderCalls ? 1 : 0
      };
      try {
        await userService.updateUserProfile(payload);
        enqueueSnackbar('Profile has been updated', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  });

  const { register, setValue, watch, handleSubmit, reset } = useForm<IFormValues>({
    defaultValues: {
      avatar: undefined,
      nickname: '',
      phone: '',
      email: '',
      comments: '',
      reminderCalls: true
    }
  });

  useEffect(() => {
    reset({
      nickname: generalData?.MyData.NickName || '',
      phone: generalData?.MyData.Phone || '',
      email: generalData?.MyData.Email || '',
      comments: generalData?.MyData.Comments || '',
      reminderCalls: generalData?.MyData.ReminderCalls === 1 ? true : false
    });
  }, [generalData]);

  const navigate = useNavigate();

  const submit = handleSubmit((values) => {
    mutate(values);
  });

  const avatarSrc = useMemo(() => {
    const avatarInput = watch('avatar');
    if (avatarInput && avatarInput.length) {
      return URL.createObjectURL(avatarInput[0]);
    }
  }, [watch('avatar')]);

  if (isGeneralDataLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="ProfilePage">
        <div className="d-flex gap-1 align-center p-2 mb-2 pointer">
          <ArrowLeftIcon onClick={() => navigate(-1)} />
          <p className="fw-700 font-lg"> My profile</p>
        </div>
        <div className="ProfilePage-content">
          <div className="d-flex justify-center mb-2 relative">
            <label
              htmlFor="upload-avatar"
              className="d-inline-flex justify-center mb-2 relative m-x-auto">
              <Avatar
                style={{ width: '72px', height: '72px' }}
                title="Shira B"
                src={
                  avatarSrc ||
                  `https://qlfiles.s3.us-east-1.amazonaws.com/avatars/st_${
                    generalData?.MyData?.Id
                  }.png?x=${randomString()}`
                }
              />
              <Button className="avatar-edit" variant="outlined">
                <PencilIcon />
              </Button>
              <input type="file" hidden id="upload-avatar" {...register('avatar')} />
            </label>
          </div>
          <Input label="Nickname" fullWidth className="mb-2" {...register('nickname')} />
          <div className="mb-1">
            <p className="fw-700 mb-1">Phone</p>
            <PhoneInput
              country={'us'}
              containerClass="phone-input-container mb-2"
              inputClass="phone-input-field"
              buttonClass="phone-input-button"
              dropdownClass="phone-input-dropdown"
              value={watch('phone')}
              onChange={(value) => {
                setValue('phone', value);
              }}
            />
          </div>
          <Input label="Email" fullWidth className="mb-2" {...register('email')} />
          <Textarea
            label="Comments"
            fullWidth
            className="mb-2"
            placeholder="Write comments.."
            {...register('comments')}
          />
          <div className="mb-1">
            <p className="fw-700 mb-1">Reminder calls</p>
            <Switcher stateOff="Off" stateOn="On" {...register('reminderCalls')} />
          </div>
        </div>
        <div className="ProfilePage-footer">
          <Button
            fullWidth
            className="mb-1"
            onClick={submit}
            disabled={isLoading}
            loading={isLoading}>
            Save Changes
          </Button>
          <Button variant="outlined" color="error" fullWidth onClick={() => setIsOpen(true)}>
            Delete Account
          </Button>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <DeleteModal onClose={() => setIsOpen(false)} />
      </Modal>
    </>
  );
};

export default ProfilePage;
