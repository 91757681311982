import React from 'react';

import { Button, Divider } from 'components/ui';
import './RequestSentModal.scss';
import PORimage from 'assets/imgs/POR.png';
const RequestSentModal = (props: { onClose: () => void }) => {
  const { onClose } = props;

  return (
    <div className="RequestSentModal">
      <div>
        <div className="d-flex direction-column align-center">
          <img
            src={PORimage}
            alt="payment request image"
            className="p-4"
            width="70px"
            height="70px"
            style={{ boxSizing: 'content-box' }}
          />
          <p className="fw-700 mb-1">All Done!</p>
          <p className="mb-2">We sent you request</p>
        </div>
      </div>
      <Divider />
      <div className="p-3 d-flex justify-center">
        <Button style={{ width: '140px' }} onClick={onClose}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default RequestSentModal;
