import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { Drawer } from 'components/ui';
import MainMenu from 'components/MainMenu';

interface SidebarContext {
  isOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
}

interface SidebarContextProps {
  children: React.ReactNode | React.ReactNode[];
}

const SidebarContext = createContext<SidebarContext | null>(null);

export const SidebarContextProvider: FC<SidebarContextProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSidebar = useCallback(() => setIsOpen(true), []);
  const closeSidebar = useCallback(() => setIsOpen(false), []);

  const contextValues = useMemo(() => ({ isOpen, openSidebar, closeSidebar }), [isOpen]);

  return (
    <SidebarContext.Provider value={contextValues}>
      <>
        <Drawer isOpen={isOpen} onClose={closeSidebar}>
          <MainMenu />
        </Drawer>
        {props.children}
      </>
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === null) {
    throw Error('useSidebar have to be used inside SidebarContextProvider');
  }
  return context;
};
