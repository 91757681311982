import { useEffect, useRef } from 'react';

export const usePrevValue = <T>(value: T): T | null => {
  const prevValue = useRef<T | null>(null);
  useEffect(() => {
    if (value) {
      prevValue.current = value;
    }
  }, [value]);

  return prevValue.current;
};
