import React, { memo, useEffect, useState } from 'react';

import CodeInput from '../Input/CodeInput';

import { usePrevValue } from 'hooks/usePrevValue';
import './OTPInput.scss';

interface IOTPInputProps {
  fieldsCount?: number;
  onChange: (otp: string) => void;
  value: string;
  disabled?: boolean;
  classNames?: string;
  error?: boolean;
  helperText?: string;
}

const OTPInput = (props: IOTPInputProps) => {
  const { fieldsCount = 4, disabled, onChange, classNames, error, helperText } = props;

  const [otp, setOtp] = useState<string[]>(new Array(fieldsCount).fill(''));
  const [activeIdx, setActiveIdx] = useState(0);
  const prevValue = usePrevValue(otp.join(''));

  useEffect(() => {
    onChange(otp.join(''));
  }, [otp]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
    if (e.key === 'Backspace') {
      if (idx !== 0) {
        e.preventDefault();
        const newOTP = [...otp];
        newOTP[idx] = '';
        setOtp(newOTP);

        setActiveIdx(idx - 1);
      } else {
        const newOTP = [...otp];
        newOTP[idx] = '';
        setOtp(newOTP);
      }
    }
  };

  const handleTypeCode = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const value = e.target.value.slice(0, fieldsCount);
    if (value.length === 1) {
      setOtp((prev) => {
        const newOtp = [...prev];
        newOtp[idx] = value.substring(value.length - 1);
        return newOtp;
      });
      setActiveIdx(idx + 1);
    } else if (!prevValue || value.length > prevValue?.length) {
      for (let i = idx; i < value.length; i++) {
        setOtp((prev) => {
          const newOtp = [...prev];
          newOtp[i] = value[i];
          return newOtp;
        });
      }
      setActiveIdx(value.length - 1);
    }
  };

  return (
    <div className={`otp-container ${classNames}`}>
      <div className="otp-container-inputs">
        {otp.map((_, idx, arr) => {
          return (
            <React.Fragment key={idx}>
              <CodeInput
                placeholder="-"
                disabled={disabled}
                value={arr[idx]}
                ref={(node) => activeIdx === idx && node?.focus()}
                onChange={(e) => handleTypeCode(e, idx)}
                onKeyDown={(e) => handleKeyDown(e, idx)}
                error={error}
              />
            </React.Fragment>
          );
        })}
      </div>
      {error && <p className="otp-error">{helperText}</p>}
    </div>
  );
};

OTPInput.displayName = 'OTPInput';

export default memo(OTPInput);
